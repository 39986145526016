
import * as firebase from "firebase/app";

import "firebase/auth";

firebase.initializeApp({
    apiKey: "AIzaSyBpDLgaOumiDn3_5RyZuCOFsOertO5-9vw",
    authDomain: "asistexa-twiggy.firebaseapp.com",
    databaseURL: "https://asistexa-twiggy.firebaseio.com",
    projectId: "asistexa-twiggy",
    storageBucket: "asistexa-twiggy.appspot.com",
    messagingSenderId: "60770823966",
    appId: "1:60770823966:web:9a51a058dcc847a32fe30f",
    measurementId: "G-04Y59T66MG"
});


export default firebase;