import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { addToCart } from '../../../actions'

import ProductItem from '../../../components/shop/ProductItem'
import { chooseDiscount, getDiscountsForArticle } from '../../../utils'

import Config from '../../../Config'
import ImageNotFound from '../../../img/image_not_found.png'


const FeaturedProducts = ({ destacados, addToCart, cart, descuentos, currentClient }) => {
    
    const [ modalIsShown, setModalIsShown ] = useState(false)
    const [ modalErrorIsShown, setErrorModalIsShown ] = useState(false)

    let clienteId = currentClient ? currentClient.clienteId : 0;

    let articulos = []
    if(destacados){
        let from = 0;
        const length = destacados.length
        while(from < length){
            const to = from + 4 <= length ? from + 4 : length
            articulos.push(destacados.slice(from, to))
            from = to
        }
    }
    const handleClose = (e) => {
        if(e && e.preventDefault)
            e.preventDefault()
        setModalIsShown(false)
    }

    const handleCloseErrorModal = (e) => {
        if(e && e.preventDefault)
            e.preventDefault()
        setErrorModalIsShown(false)
    }

    const handleAddToCart = (articulo) =>{
        const articuloId = articulo.articuloId;
        const cantidad = articulo.cantidad;
        const cantidadDisponible = articulo.cantidadDisponible;
        if(!cart[articuloId] || 
            cart[articuloId].cantidad + cantidad <= cantidadDisponible){
            
            addToCart(articulo)
    
            setModalIsShown(true)
            setErrorModalIsShown(false)
        }else{
            
            setModalIsShown(false)
            setErrorModalIsShown(true)
        }
    }

    return (
        <>

        <Modal show={ modalIsShown  } onHide={handleClose}>
            <Modal.Body>
                <div className="alert alert-success text-center margin-bottom-1x">
                    <i className="icon-check-circle"></i>&nbsp;&nbsp;
                    Su producto ha sido agregado al carrito
                </div>

            </Modal.Body>
            <Modal.Footer>
                <a href="#" className="btn btn-secondary" onClick={handleClose}>
                    Continuar comprando
                </a>
                <Link to="/carrito" className="btn btn-primary">
                    Ir al Carrito
                    <i className="icon-arrow-right"></i>
                </Link>
            </Modal.Footer>
        </Modal>
        <Modal show={ modalErrorIsShown  } onHide={ handleCloseErrorModal }>
            <Modal.Body>
                <div className="col-sm-12 mt-3">
                    <div className="alert alert-danger fade show text-center">
                        <i className="icon-slash"></i>&nbsp;&nbsp;
                        <span className="text-medium">No puede agregar mas items al carrito::</span>&nbsp;
                        La cantidad seleccionada, mas la que ya ha agregado al carrito de compras,
                        excede a la cantidad disponible.
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <a href="#" className="btn btn-secondary" onClick={handleCloseErrorModal}>
                    Continuar comprando
                </a>
            </Modal.Footer>
        </Modal>

        <section className="container padding-bottom-2x mb-2 padding-top-3x">
            <h2 className="h3 pb-3 text-center">Productos Destacados</h2>
            {
                articulos.map((subArray, index) => {
                    return  (
                        <div  key={index} className="row row-products">
                        {
                            subArray.map((articulo) => {
                                return  (
                                    <div  key={ articulo.articuloId } className="col-md-3 col-sm-4">
                                        <ProductItem nombreFamilia={ articulo.familiaNombre }
                                            nombreArticulo={ articulo.descripcion }
                                            precio={ articulo.precioPorMenor }
                                            articuloId={ articulo.articuloId }
                                            onAddToCart={ handleAddToCart }
                                            familiaId={ articulo.familiaId }
                                            descuentos={ getDiscountsForArticle(descuentos,articulo.articuloId, articulo.familiaId, clienteId ) }
                                            cantidadDisponible={ articulo.cantidadDisponible }
                                imagenUrl={ articulo.fotografiaId > 0 ? `${ Config.asistexaURL }image_${ articulo.fotografiaId }_300_300.jpg` : ImageNotFound } />
                                    </div>
                                )
                            })
                        }
                        </div>
                    )
                })

                
            }
            <div className="text-center">
                <Link to="/productos" className="btn btn-outline-secondary" href="shop-grid-ls.html">
                    Ver todos los Productos
                </Link>
            </div>
        </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        cart : state.cart,
        descuentos : state.descuentos,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = { 
    addToCart
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedProducts);

