import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { strTofriendlyUrl } from '../../../utils'

import 'owl.carousel2/dist/assets/owl.carousel.min.css';

import ImageNotFound from '../../../img/image_not_found.png'
import $ from 'jquery'
import Config from '../../../Config';
window.jQuery = window.$ = $;

require('owl.carousel2');


const HomeSlider = ({ items }) => {

    useEffect(() => {
        if (items && items.length > 0) {
            $('.store-slider').owlCarousel({
                items: 1,
                loop: true,
                dots: true,
                nav : true,
                navText : ['','']
            });
        }
        
    }, [items])
        
    
        //this.$el.owlCarousel('destroy');
    if(!items || items.length == 0)
        return ( 
            <div className="owl-carousel large-controls dots-inside"
                data-owl-carousel="{ &quot;nav&quot;: true, &quot;dots&quot;: true, &quot;loop&quot;: true, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000 }">
            </div>
        );

    return (
        <div className="owl-carousel large-controls dots-inside store-slider"
            data-owl-carousel="{ &quot;nav&quot;: true, &quot;dots&quot;: true, &quot;loop&quot;: true, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000 }">

            {
                items.map((item) => {
                    const marcaImageUrl = `${ Config.asistexaURL }image_${ item.fotografiaMarcaId }_300_300.jpg`

                    const imageUrl  = item.fotografiaId > 0 ? `${ Config.asistexaURL }image_${ item.fotografiaId }_555_440.jpg` : ImageNotFound;
                    const relativeUrl = `/producto/${item.articuloId}/${strTofriendlyUrl(item.descripcion)}`
                    return (
                    <div className="item" key={ item.articuloId }>
                        <div className="container padding-top-3x">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-6 padding-bottom-2x text-md-left text-center">
                                    <div className="from-bottom">
                                        {
                                            item.fotografiaMarcaId > 0 &&
                                            <img className="d-inline-block w-150 mb-4" src={ marcaImageUrl } alt={ item.nombreMarca } />
                                        }                                        
                                        <div className="h2 text-body mb-2 pt-1">{ item.descripcion }</div>
                                    </div>
                                    <Link className="btn btn-primary scale-up delay-1"
                                        to={ relativeUrl }>
                                        Ver Producto&nbsp;<i className="icon-arrow-right"></i>
                                    </Link>
                                </div>
                                <Link className="col-md-6 padding-bottom-2x mb-3"
                                    to={ relativeUrl }>
                                        <img className="d-block mx-auto" src={imageUrl} alt={ item.descripcion } />
                                </Link>
                            </div>
                        </div>
                    </div>
                    )
                })

            }
{/*
                <div className="item">
                    <div className="container padding-top-3x">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-5 col-md-6 padding-bottom-2x text-md-left text-center">
                                <div className="from-bottom"><img className="d-inline-block w-150 mb-4" src={ require('../../../img/hero-slider/logo02.png') } alt="Puma" />
                                    <div className="h2 text-body mb-2 pt-1">Casco Half Dome Black Diamond</div>
                                </div>
                                <Link className="btn btn-primary scale-up delay-1"
                                    to="/producto/76/casco-half-dome-black-diamond">
                                    Ver Producto&nbsp;<i className="icon-arrow-right"></i>
                                </Link>
                            </div>
                            <div className="col-md-6 padding-bottom-2x mb-3">
                                <img className="d-block mx-auto" src={ require('../../../img/hero-slider/02.png') } alt="Puma Backpack" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="container padding-top-3x">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-5 col-md-6 padding-bottom-2x text-md-left text-center">
                                <div className="from-bottom"><img className="d-inline-block w-150 mb-3" src={ require('../../../img/hero-slider/logo01.png') } alt="Sony" />
                                    <div className="h2 text-body mb-2 pt-1">Botas Merrell Chamaleon Mid Waterproof</div>
                                    
                                </div>
                                <Link className="btn btn-primary scale-up delay-1"
                                    to="/producto/16/botas-merrell-chamaleon-mid-waterproof">
                                    Ver Producto&nbsp;<i className="icon-arrow-right"></i>
                                </Link>
                            </div>
                            <div className="col-md-6 padding-bottom-2x mb-3">
                                <img className="d-block mx-auto" src={ require('../../../img/hero-slider/01.png') } alt="Chuck Taylor All Star II" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="container padding-top-3x">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-5 col-md-6 padding-bottom-2x text-md-left text-center">
                                <div className="from-bottom"><img className="d-inline-block w-150 mb-3" src={ require('../../../img/hero-slider/logo03.png') } alt="Motorola" />
                                    <div className="h2 text-body mb-2 pt-1">Carpa OURSKY para 2 personas</div>
                                    
                                </div>
                                <Link className="btn btn-primary scale-up delay-1"
                                    to="/producto/10/carpa-oursky-para-2-personas">
                                    Ver Producto&nbsp;<i className="icon-arrow-right"></i>
                                </Link>
                            </div>
                            <div className="col-md-6 padding-bottom-2x mb-3"><img className="d-block mx-auto" src={ require('../../../img/hero-slider/03.png') } alt="Moto 360" /></div>
                        </div>
                    </div>
</div>*/}
            </div>
    )
}

export default HomeSlider