import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux'
import { createStore, compose } from 'redux'
import reducer from './reducers'
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from './firebaseConfig'
import Config from './Config'

const strCart = localStorage.getItem("cart");
const cart = strCart ? JSON.parse(strCart) : {}

const strClient = localStorage.getItem("client");
const client = strClient || strClient != "" ? JSON.parse(strClient) : null

const initialState = {
  mobileMenuIsOpen : false,
  cart: cart,
  cartChangesCounter : 0, 
  descuentos : [],
  firebase : firebase,
  currentUser : null,
  currentClient : client,
  gaID : null,
  configuracionSeo : {
    ciudad: "",
    descripcionComercial: Config.siteDescription,
    direccion: null,
    fotoTiendaFileId: 0,
    latitud: Config.gpsDefaultPosition.lat,
    longitud: Config.gpsDefaultPosition.lng,
    logoFileId: 0,
    nombreComercial: Config.siteName,
    telefonoTienda: Config.whatsappForOrder,
    whatsappAtencionCliente:  Config.whatsappForOrder,
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancers());

ReactDOM.render(
  <HelmetProvider>
    <Provider store={ store }>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </HelmetProvider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
