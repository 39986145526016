import React, { useState, useEffect } from 'react'
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import Config from '../../../Config';

const containerStyle = {
    width: '100%',
    height: '400px'
  };
 

const Shipping = (props) => {

    const [position, setPosition ] = useState(props.position);
    const [dragging, setDragging ] = useState(false)
    const [stateObject, setStateObject] = useState({
            wasValidated : false,
            city: props.city,
            cityName: props.cityName,
            address : props.address,
            addressValidationMessage : "",
            cityValidationMessage : "",
            costoEnvioValidationMessage: "",
            requestGeolocation : props.requestGeolocation
        });
    const [ costoEnvio , setCostoEnvio ] = useState(props.costoEnvio);

    const [marker, setMarker] = useState(null)

    const onLoadMarker = React.useCallback(function callback(marker) {
        setMarker(marker)
        getCostoEntrega(marker.position);
        
    }, [])

    const onUnmountMarker = React.useCallback(function callback(marker) {
        setMarker(null)
      }, [])

    useEffect( () => {
        if(!stateObject.requestGeolocation)
            return;
        navigator && navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;

            const currentPosition = {
                lat: latitude,
                lng: longitude
            };
            setPosition(currentPosition);
        });
    },[]);

    const handleLocationChange = () => {
        if(marker)
            setPosition({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            })
    }

    const handleDragEnd = () => {
        setDragging(false);
        getCostoEntrega();
    }

    const handleMarkerPositionChanged = () => {    
        if(!marker || !marker.position || dragging)
            return;    
        getCostoEntrega();
    }

    const getCostoEntrega = (position) =>{
        if(!position){
            position = marker.position;
        }
        const latitud = position.lat()
        const longitud = position.lng()
        const url = `${Config.apiURL}api/pedido/costo-entrega?latitud=${latitud}&longitud=${longitud}`
        const headers = {}
        
        const token = localStorage.getItem("token");
        if(token)
            headers.Authorization = `Bearer ${ token }`

        fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then((jsonData) => {
                console.log("-------")
                console.log(jsonData)
                const obj = {
                    estado : jsonData.data.posible ? "Posible" : "NoPosible",
                    costo : jsonData.data.costo
                }
                console.log("-------")
                console.log(obj);
                props.changeCostoEnvio(obj)
                setCostoEnvio(obj)
            })
            .catch(err => console.log(err))

    }

    const handleMapClick = (e) => {
        console.log(e)
        marker.setPosition( e.latLng );
    }

    const changeAddress = (e) => {
        setStateObject({
            ...stateObject,
            address : e.target.value
        })
    }

    const changeCity = (e) => {
        
        setStateObject({
            ...stateObject,
            city: e.target.value,
            cityName : e.target.item(e.target.selectedIndex).text
        })
    }

    const handleNextButton = (e) => {
        e.preventDefault();
        let isInvalid = false;
        
        const newState = {}
        stateObject.costoEnvioValidationMessage = "";
        if(costoEnvio.estado == "NoCalculado"){
            stateObject.costoEnvioValidationMessage = "Debe seleccionar una posicion en el mapa para calcular el costo de entrega";
            isInvalid = true;
        }
        if(costoEnvio.estado == "NoPosible"){
            stateObject.costoEnvioValidationMessage = "No es posible entregar su pedido en la ubicación seleccionada";
            isInvalid = true;
        }
        /*stateObject.cityValidationMessage = "";
        if(stateObject.city.trim() == ""){
            stateObject.cityValidationMessage = "Debe seleccionar una ciudad";
            isInvalid = true;
        }*/
        stateObject.addressValidationMessage = "";
        if(stateObject.address.trim() == ""){
            stateObject.addressValidationMessage = "Es necesario que ingrese detalladamente su dirección de envio";
            isInvalid = true;
        }
        setStateObject({
            ...stateObject,
            wasValidated: true
        })
        //setDragging(false);
        if(!isInvalid)
            props.onNextSection({
                address: stateObject.address,
                city: stateObject.city,
                cityName: stateObject.cityName,
                latitud : marker.position.lat(),
                longitud: marker.position.lng(),
                requestGeolocation : false
            });
    }

    const handleBackButton = (e) => {
        e.preventDefault();
        props.onBackButton();
    }

    const [ autocomplete, setAutocomplete ] = useState(null);

    const onAutocompleteLoad = (autocomplete) => {
        setAutocomplete(autocomplete)
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if(place){
                setPosition(place.geometry.location);
                setStateObject({
                    ...stateObject,
                    address: place.formatted_address
                })
            }
          } else {
            console.log('Autocomplete is not loaded yet!')
          }
    }

    return (
        <>
            <h4>Datos de Entrega</h4>
            <hr className="padding-bottom-1x"></hr>

            <div className={ stateObject.wasValidated ? "row was-validated" : "row" }>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>
                            Seleccione la ubicación en el mapa donde desea que le entreguen su producto. 
                            Puede hacer uso del buscador para encontrar su ubicación o puede dar click en el mapa 
                            para marcarla
                        </label>
                        
                        <div 
                            className={ `map${ stateObject.costoEnvioValidationMessage == "" ? "" : " is-invalid" }` }>
                            
                            <LoadScript
                                libraries={ ["places"] }
                                googleMapsApiKey={ Config.GoogleMapApiKey }
                                >
                                <Autocomplete
                                    onLoad={ onAutocompleteLoad }
                                    onPlaceChanged={ onPlaceChanged} 
                                >
                                    <input
                                        type="text" className="form-control"
                                        placeholder="Puede buscar su ubicación ingresando la calle o avenida de su dirección"
                                        style={{
                                            borderWidth: `0px`,
                                            borderBottom: '1px solid #e0e0e0'
                                        }}
                                    />
                                </Autocomplete>
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={ position }
                                    zoom={14}
                                    onClick={ handleMapClick }
                                    >
                                     
                                    <Marker onLoad={ onLoadMarker }
                                        onUnmount={ onUnmountMarker }
                                        onDragStart={ () => { setDragging(true) } }
                                        onDragEnd={ handleDragEnd }
                                        onPositionChanged={ handleMarkerPositionChanged }
                                        position={ position } draggable />
                                </GoogleMap>
                            </LoadScript>

                        </div>
                        {
                            stateObject.costoEnvioValidationMessage && stateObject.costoEnvioValidationMessage != '' ?
                            <div className="invalid-feedback alert alert-danger">{ stateObject.costoEnvioValidationMessage  }</div>
                            : ""
                        }
                        
                    </div>
                </div>
                <div className="col-sm-6" style={ {display:"none"} }>
                    <div className="form-group">
                        <label htmlFor="select-ciudad">Ciudad</label>
                        <select name="ciuidad" id="select-ciudad"
                            onChange={ changeCity } value={ stateObject.city}
                            className={ `form-control${ stateObject.cityValidationMessage == "" ? "" : " is-invalid" }` }>
                            <option value="">Seleccione una ciudad...</option>
                            <option value="1">LA PAZ</option>
                            <option value="2">COCHABAMBA</option>
                            <option value="3">SANTA CRUZ</option>
                            <option value="4">TARIJA</option>
                            <option value="5">CHUQUISACA</option>
                            <option value="6">ORURO</option>
                            <option value="7">POTOSI</option>
                            <option value="8">PANDO</option>
                            <option value="9">BENI</option>

                        </select>
                        <div className="invalid-feedback">{ stateObject.cityValidationMessage  }</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="direccion">Detalle su dirección o alguna intrucción adicional para su entrega</label>
                        <textarea className={ `form-control${ stateObject.addressValidationMessage == "" ? "" : " is-invalid" }` } id="direccion"
                            name="direccion" onChange={ changeAddress } rows="5" value={ stateObject.address }></textarea>
                        <div className="invalid-feedback">{ stateObject.addressValidationMessage  }</div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between paddin-top-1x mt-4">
                <a href="#" className="btn btn-outline-secondary"
                    onClick={ handleBackButton }>
                        <i className="icon-arrow-left"></i>
                        <span className="hidden-xs-down">&nbsp;
                        Atras
                    </span>
                </a>
                <a className="btn btn-primary" href="#" onClick={ handleNextButton }>
                    <span className="hidden-xs-down">Continuar&nbsp;</span>
                    <i className="icon-arrow-right"></i>
                </a>
            </div>
            
        </>
    )
}
export default React.memo(Shipping)