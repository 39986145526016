import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { removeFromCart } from '../../../actions'
import { formatNumber, strTofriendlyUrl } from '../../../utils'

import MiniCartItem from './MiniCartItem'

const MiniCart = (props) => {

    let total = 0;
    const cartItems = [] 
    for (const id in props.cart) {
        const articulo = props.cart[id];
        if(!articulo.precioEnUso)
            articulo.precioEnUso = articulo.precio;
        cartItems.push(articulo);
        total += articulo.precioEnUso * articulo.cantidad
    }

    if(cartItems.length == 0){
        return (
        <div className="toolbar-dropdown cart-dropdown widget-cart hidden-on-mobile text-center">
            <i className="icon-shopping-bag" style={ { fontSize : "40px" } }></i>
            <br/><br/>
            Actualmente su carrito de compras está vacio.
        </div>
        )


    }
    
    return (
        <div className="toolbar-dropdown cart-dropdown widget-cart hidden-on-mobile">
            {
                cartItems.map((articulo, index) => {
                    return  (
                        <MiniCartItem key={ articulo.articuloId }
                            onRemoveItem={ props.removeFromCart }
                            nombreArticulo={ articulo.nombreArticulo }
                            articuloId={ articulo.articuloId }
                            cantidad={ articulo.cantidad }
                            friendlyNameUrl={ strTofriendlyUrl(articulo.nombreArticulo) }
                            precioForDislay={ formatNumber(articulo.precioEnUso) }
                            imagenUrl={ articulo.imagenUrl } />
                        
                    )
                })
            }
            <div className="text-right">
                <p className="text-gray-dark py-2 mb-0">
                    <span className="text-muted">Subtotal:</span> 
                    Bs. {  formatNumber(total) }
                </p>
            </div>
            <Link className="btn btn-primary btn-sm btn-block mb-0"
                to="/checkout">
                Realizar Pedido
            </Link>
            <Link className="btn btn-secondary btn-sm btn-block mb-0"
                to="/carrito">
                Ver Carrito
            </Link>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        cart : state.cart,
        cartChangesCounter: state.cartChangesCounter
    }
}
const mapDispatchToProps = { 
    removeFromCart
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniCart);