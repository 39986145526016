import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import NavigationRoutes from './routes/NavigationRoutes'
import { setCurrentUser } from './actions'

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import './css/style.fixes.css';
import './css/feather.min.css';
import './css/socicon.min.css';

function App({ firebase, setCurrentUser }) {

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => setCurrentUser({ currentUser : user }));
  })

  return (    
    <React.Fragment>
      <NavigationRoutes/>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
      firebase : state.firebase
  }
}
const mapDispatchToProps = { 
  setCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
