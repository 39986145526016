import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Config from '../Config'
import { updateSEOInfo } from '../actions'

import GAComponent from '../components/analytics/GAComponent'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import DemoAlert from '../components/DemoAlert';
import Home from '../react-pages/home/Home'
import Products from '../react-pages/products/Products'
import About from '../react-pages/about/About'
import Cart from '../react-pages/cart/Cart'
import SingleProduct from '../react-pages/single-product/SingleProduct'
import Checkout from '../react-pages/checkout/Checkout';
import PedidoFinalizado from '../react-pages/pedido-finalizado/PedidoFinalizado';
import Login from '../react-pages/login/Login';
import Welcome from '../react-pages/welcome/Welcome';
import Profile from '../react-pages/account/Profile';
import Orders from '../react-pages/account/Orders';
import Delivery from '../react-pages/delivery/Delivery'
import PagoQR from '../react-pages/pago-qr/PagoQR';
import NotFound from '../react-pages/NotFound'
import EmailVerification from '../react-pages/email-verification/EmailVerification'



const NavigationRoutes = ({ gaID, currentClient, cart, updateSEOInfo }) => {
    
    const [ categorias, setCategorias ] = useState([])

	useEffect(() =>{
		fetch(`${Config.apiURL}api/familia`)
            .then(response => response.json())
            .then(jsonData => {
                
                updateSEOInfo({
                    seo: jsonData.configuracionSeo
                });

                const familias = jsonData.familias;
				if(familias.length <= 4){
					setCategorias([familias])
					return;
                }
				let filasNecesarias = familias.length / 4.0;
				let requiereExtra = filasNecesarias - parseInt(filasNecesarias) > 0;
				const categoriasPorColumna = 4;
                const categories = []
				let from = 0;
                while(from < familias.length){
					let to = from + categoriasPorColumna <= familias.length ? from + categoriasPorColumna : jsonData.length
					if(requiereExtra && to + 1 < familias.length)
						to++;
					requiereExtra = false
                    categories.push(familias.slice(from, to))
                    from = to
                }
                setCategorias(categories)
            })
            .catch(err =>{
                console.log(err)
            })
	},[])


    return (
        <BrowserRouter>
            <GAComponent gaID={ gaID } /> 
            <Header categorias={ categorias } />

            <Switch>
                <Route exact path="/" component={ Home }/>
                <Route exact path="/producto/:productoId/:productName" component={ SingleProduct }/>
                <Route exact path="/productos" component={ Products }/>
                <Route exact path="/productos/categoria/:categoriaId/:categoryName"  component={ Products } />
                <Route exact path="/productos/marca/:marcaId/:marcaName" component={ Products } />
                <Route exact path="/productos/subcategoria/:subCategoriaId/:subCategoryName" component={ Products } />
                <Route exact path="/carrito" component={ Cart }/>
                <Route exact path="/entrega/:ventaId" component={ Delivery }/>
                <Route exact path="/checkout">
                    {
                        currentClient ? 
                            (cart && Object.keys(cart).length > 0 ? <Checkout /> : <Redirect to="/carrito" /> ) 
                            : <Redirect to="/login" />
                    }
                </Route>
                <Route exact path="/nosotros" component={ About }/>
                <Route exact path="/pedido-finalizado" component={ PedidoFinalizado }/>
                <Route exact path="/verificar-email" component={ EmailVerification }/>
                <Route exact path="/login" >
                    { currentClient ? <Redirect to="/" /> : <Login /> }
                </Route>
                <Route exact path="/welcome" >
                    { currentClient ? <Welcome /> : <Redirect to="/" />  }
                </Route>
                <Route exact path="/mi-cuenta/mis-datos" >
                    { currentClient ? <Profile /> : <Redirect to="/login" />  }
                </Route>
                <Route exact path="/mi-cuenta/pedidos" >
                    { currentClient ? <Orders /> : <Redirect to="/login" />  }
                </Route>
                <Route exact path="/pago-qr/:ventaId" >
                    { currentClient ? <PagoQR /> : <Redirect to="/login" />  }
                </Route>
                <Route component={ NotFound } />
            </Switch>
            <Footer categorias={ categorias }/>
        </BrowserRouter>
    )
}

const mapStateToProps = state => {
    return {
        currentClient : state.currentClient,
        cart : state.cart,
        gaID : state.gaID
    }
}


const mapDispatchToProps = { 
    updateSEOInfo
};


export default connect(mapStateToProps, mapDispatchToProps)(NavigationRoutes)