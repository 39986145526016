import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux'
import { addToCart } from '../../../actions'
import { Link } from 'react-router-dom'

import ProductItem from '../../../components/shop/ProductItem'
import { getDiscountsForArticle } from '../../../utils'
import Config from '../../../Config'

import ImageNotFound from '../../../img/image_not_found.png'

const ProductList = ( { articulos, cart, addToCart, descuentos, currentClient } ) => {

    const [ modalIsShown, setModalIsShown ] = useState(false)
    const [ modalErrorIsShown, setErrorModalIsShown ] = useState(false)    

    const clienteId = currentClient ? currentClient.clienteId : 0;

    const handleClose = (e) => {
        if(e && e.preventDefault)
            e.preventDefault()
        setModalIsShown(false)
    }

    const handleCloseErrorModal = (e) => {
        if(e && e.preventDefault)
            e.preventDefault()
        setErrorModalIsShown(false)
    }

    

    const handleAddToCart = (articulo) =>{
        const articuloId = articulo.articuloId;
        const cantidad = articulo.cantidad;
        const cantidadDisponible = articulo.cantidadDisponible;
        if(!cart[articuloId] || 
            cart[articuloId].cantidad + cantidad <= cantidadDisponible){
            
            addToCart(articulo)
    
            setModalIsShown(true)
            setErrorModalIsShown(false)
        }else{
            
            setModalIsShown(false)
            setErrorModalIsShown(true)
        }
    }

    if(articulos.length == 0){

        return (
            <div className="row">
                <div className="col-md-12 ">
                    <div className="text-center pt-5 pb-5">
                        <div  style={ { fontSize : "68px" } }>
                            <i className="feather icon-box"></i>
                        </div>
                        <p>
                            No hay productos que coincidan con su criterio de búsqueda.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
    <>
        

        <Modal show={ modalIsShown  } onHide={handleClose}>
            <Modal.Body>
                <div className="alert alert-success text-center margin-bottom-1x">
                    <i className="icon-check-circle"></i>&nbsp;&nbsp;
                    Su producto ha sido agregado al carrito
                </div>

            </Modal.Body>
            <Modal.Footer>
                <a href="#" className="btn btn-secondary" onClick={handleClose}>
                    Continuar comprando
                </a>
                <Link to="/carrito" className="btn btn-primary">
                    Ir al Carrito
                    <i className="icon-arrow-right"></i>
                </Link>
            </Modal.Footer>
        </Modal>
        <Modal show={ modalErrorIsShown  } onHide={ handleCloseErrorModal }>
            <Modal.Body>
                <div className="col-sm-12 mt-3">
                    <div className="alert alert-danger fade show text-center">
                        <i className="icon-slash"></i>&nbsp;&nbsp;
                        <span className="text-medium">No puede agregar mas items al carrito:</span>&nbsp;
                        La cantidad seleccionada, mas la que ya ha agregado al carrito de compras,
                        excede a la cantidad disponible.
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <a href="#" className="btn btn-secondary" onClick={handleCloseErrorModal}>
                    Continuar comprando
                </a>
            </Modal.Footer>
        </Modal>
        {
            articulos.map((subArray, index) => {
                return  (
                    <div  key={index} className="row row-products">
                    {
                        subArray.map((articulo, index) => {
                            return  (
                                <div  key={ articulo.articuloId } className="col-md-4 col-sm-6">
                                    <ProductItem nombreFamilia={ articulo.familiaNombre }
                                        nombreArticulo={ articulo.descripcion }
                                        precio={ articulo.precioPorMenor }
                                        articuloId={ articulo.articuloId }
                                        familiaId={ articulo.familiaId }
                                        onAddToCart={ handleAddToCart }
                                        descuentos={ getDiscountsForArticle(descuentos,articulo.articuloId, articulo.familiaId, clienteId ) }
                                        cantidadDisponible={ articulo.cantidadDisponible }
                                        imagenUrl={ articulo.fotografiaId > 0 ? `${ Config.asistexaURL }image_${ articulo.fotografiaId }_300_300.jpg` : ImageNotFound } />
                                </div>
                            )
                        })
                    }
                    </div>
                )
            })
        }
    </>
    )
}

const mapStateToProps = state => {
    return {
        descuentos : state.descuentos,
        cart : state.cart,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = { 
    addToCart
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

