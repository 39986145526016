import React, { useState } from 'react'

const Payment = ({ onBackButton, onNextSection, selectedPaymentType, onChangePaymentType }) => {

    const handleBackButton = (e) =>{
        e.preventDefault();
        onBackButton();
    }
    const handleNextButton = (e) =>{
        e.preventDefault();
        onNextSection();
    }

    return (
        <>
            <h4>Seleccione su Medio de Pago</h4>
            <hr className="padding-bottom-1x"></hr>

            <div className="row">

                <div className="col-md-12">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <td className="align-middle">
                                    <div className="custom-control custom-radio mb-0">
                                        <input className="custom-control-input" type="radio" name="payment" id="efectivo"
                                            checked={ selectedPaymentType == "ContraEntrega" }
                                            onClick={ () => onChangePaymentType("ContraEntrega")  }/>
                                        <label className="custom-control-label" htmlFor="efectivo"></label>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <label htmlFor="efectivo" className="labelPayment">
                                        <span className="text-gray-dark">Pago contra Entrega</span><br/>
                                        <span className="text-muted text-sm">Al recibir su producto, deberá cancelar el monto total de la compra</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                
                                <td className="align-middle">
                                    <div className="custom-control custom-radio mb-0">
                                        <input className="custom-control-input" type="radio" name="payment" id="qr"
                                        checked={ selectedPaymentType == "PagoQR" }
                                        onClick={ () => onChangePaymentType("PagoQR")  } />
                                        <label className="custom-control-label" htmlFor="qr"></label>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <label htmlFor="qr" className="labelPayment">
                                        <span className="text-gray-dark">Transferencia Simple QR</span><br/>
                                        <span className="text-muted text-sm">
                                            Al realizar el pedido, se le generará un código QR el cual puede leer con la aplicación
                                            de Banca Movil de su Banco para hacer la transferencia.
                                        </span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="d-flex justify-content-between paddin-top-1x mt-4">
                <a href="#" className="btn btn-outline-secondary"
                    onClick={ handleBackButton }>
                        <i className="icon-arrow-left"></i>
                        <span className="hidden-xs-down">&nbsp;
                        Atras
                    </span>
                </a>
                <a className="btn btn-primary" href="#" onClick={ handleNextButton }>
                    <span className="hidden-xs-down">Continuar&nbsp;</span>
                    <i className="icon-arrow-right"></i>
                </a>
            </div>
        </>
    )
}
export default Payment