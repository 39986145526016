import React, { useState } from 'react'
import { Link } from 'react-router-dom'

class GeneralInformation extends React.Component {    

    constructor(props){
        super(props);

        this.state = {
            data : {
                nombreCompleto: props.nombreCompleto,
                telefono:  props.telefono,
                nit: props.nit,
                razonSocial:  props.razonSocial
            },
            wasValidated : false,
            invalidMessagesForState : {
                nombreCompleto: "",
                telefono: "",
                nit: "",
                razonSocial: ""
            }
        }

        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleNextButton = this.handleNextButton.bind(this);
    }


    handleChangeValue(e) {
        this.state.data[e.target.name] = e.target.value;
        this.setState({
            ...this.state
        });
    }

    handleNextButton(e) {
        e.preventDefault();
        let invalid = false;
        this.state.invalidMessagesForState.nombreCompleto = "";
        if(this.state.data.nombreCompleto.trim() == ""){
            this.state.invalidMessagesForState.nombreCompleto = "Debe ingresar su nombre completo";
            invalid = true;
        }

        this.state.invalidMessagesForState.telefono = "";
        if(this.state.data.telefono.trim() == ""){
            this.state.invalidMessagesForState.telefono = "Debe ingresar su número de teléfono";
            invalid = true;
        }

        this.state.invalidMessagesForState.nit = "";
        if(this.state.data.nit.trim() != "" && !/^\d+$/.test(this.state.data.nit.trim())){
            this.state.invalidMessagesForState.nit = "Debe ingresar solo números para el NIT";
            invalid = true;
        }
        this.setState(this.state)
        if(!invalid)
            this.props.onNextSection(this.state.data)
    }


    render() {
        return (
            <>
            <h4>Información del Cliente</h4>
            <hr className="padding-bottom-1x"></hr>

            <div className={ this.state.wasValidated ? "row was-validated" : "row" }>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="nombreCompleto">Nombre Completo</label>
                        <input className={ `form-control${ this.state.invalidMessagesForState.nombreCompleto != "" ? ' is-invalid' : '' }` }
                            type="text" id="nombreCompleto"
                            value={ this.state.data.nombreCompleto }
                            name="nombreCompleto" onChange={ this.handleChangeValue } />
                        <div className="invalid-feedback">{ this.state.invalidMessagesForState.nombreCompleto  }</div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="telefono">Número de Teléfono</label>
                        <input className={ `form-control${ this.state.invalidMessagesForState.telefono != "" ? ' is-invalid' : '' }` }
                            type="text" id="telefono"
                            value={ this.state.data.telefono }
                            name="telefono" onChange={ this.handleChangeValue } />
                        <div className="invalid-feedback">{ this.state.invalidMessagesForState.telefono  }</div>
                    </div>
                </div>
            </div>

            <h4>Datos de Facturación</h4>
            <hr className="padding-bottom-1x"></hr>

            <div className={ this.state.wasValidated ? "row was-validated" : "row" }>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="razonSocial">Razon Social</label>
                        <input className={ `form-control${ this.state.invalidMessagesForState.razonSocial != "" ? ' is-invalid' : '' }` }
                            type="text" id="razonSocial"
                            value={ this.state.data.razonSocial }
                            name="razonSocial" onChange={ this.handleChangeValue } />
                        <div className="invalid-feedback">{ this.state.invalidMessagesForState.razonSocial  }</div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="nit">NIT</label>
                        <input className={ `form-control${ this.state.invalidMessagesForState.nit != "" ? ' is-invalid' : '' }` }
                            type="text" id="nit"
                            value={ this.state.data.nit }
                            name="nit" onChange={ this.handleChangeValue } />
                        <div className="invalid-feedback">{ this.state.invalidMessagesForState.nit  }</div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between paddin-top-1x mt-4">
                <Link className="btn btn-outline-secondary"
                    to="/carrito">
                        <i className="icon-arrow-left"></i>
                        <span className="hidden-xs-down">&nbsp;
                        Volver al Carrito de Compras
                    </span>
                </Link>
                <a className="btn btn-primary" href="#" onClick={ this.handleNextButton }>
                    <span className="hidden-xs-down">Continuar&nbsp;</span>
                    <i className="icon-arrow-right"></i>
                </a>
            </div>
        </>
        )
    }
}

export default GeneralInformation
