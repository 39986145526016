export const toggleMobileMenu = payload => ({
    type: 'TOGGLE_MOBILE_MENU'
})

export const changeMobileMenuIsOpen = payload => ({
    type: 'CHANGE_MOBILE_MENU_IS_OPEN',
    payload
})

export const addToCart = payload => ({
    type: 'ADD_TO_CART',
    payload
})

export const removeFromCart = payload => ({
    type: 'REMOVE_FROM_CART',
    payload
})

export const clearCart = payload => ({
    type: 'CLEAR_CART',
    payload
})

export const updateCart = payload => ({
    type: 'UPDATE_CART',
    payload
})

export const updateDiscounts = payload => ({
    type: 'UPDATE_DISCOUNTS',
    payload
})

export const setCurrentUser = payload => ({
    type: 'SET_USER',
    payload
})

export const setCurrentClient = payload => ({
    type: 'SET_CLIENT',
    payload
})

export const updateGAID = payload => ({
    type: 'UPDATE_GAID',
    payload
})
export const updateSEOInfo = payload => ({
    type: 'UPDATE_SEO_INFO',
    payload
})

