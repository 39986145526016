import React from 'react'

const CheckoutSteps = ({ currentSection, onSectionChanged }) => {

    const handleSectionChange = (e) =>{
        if(e.preventDefault)
            e.preventDefault();
        if(e.stopPropagation)
            e.stopPropagation();
        const section = parseInt(e.target.dataset.section)
        
        if(section < currentSection){
            console.log("Changing section to: " + section)
            onSectionChanged(section)

        }

    }

    return (
        <div className="steps flex-sm-nowrap mb-5">
            <a className={ `step${ currentSection == 1 ? " active" : ""}` } href="#"
                data-section="1"
                onClick={ handleSectionChange }>
                <h4 className="step-title"
                    data-section="1"
                    onClick={ handleSectionChange }>                    
                    { currentSection > 1 && <i className="icon-check-circle"></i> }
                    1. Información
                </h4>
            </a>
            <a className={ `step${ currentSection == 2 ? " active" : ""}` } href="#"
                data-section="2"
                onClick={ handleSectionChange }>
                <h4 className="step-title"
                    data-section="2"
                    onClick={ handleSectionChange }>
                    { currentSection > 2 && <i className="icon-check-circle"></i> }
                    2. Envío
                </h4>
            </a>
            <a className={ `step${ currentSection == 3 ? " active" : ""}` } href="#"
                data-section="3"
                onClick={ handleSectionChange.bind(this) }>
                <h4 className="step-title"
                    data-section="3"
                    onClick={ handleSectionChange }>
                    { currentSection > 3 && <i className="icon-check-circle"></i> }
                    3. Pago
                </h4>
            </a>
            <a className={ `step${ currentSection == 4 ? " active" : ""}` } href="#"
                data-section="4"
                onClick={ handleSectionChange }>
                <h4 className="step-title"
                    data-section="4"
                    onClick={ handleSectionChange }>
                    4. Revisión
                </h4>
            </a>
        </div>
    )
}

export default CheckoutSteps;
