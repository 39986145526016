import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { setCurrentUser } from '../../../actions'
import MiniCart from './MiniCart'

const ToolbarHeader = (props) => {

    const handleMobileMenuToggle =(e) =>{
        e.preventDefault();
        props.onClickInMobileMenu();
    }
    
    
    let badge = ""
    if(props.cart && Object.keys(props.cart).length > 0)
        {
            badge =  <span className="count-label">
                        { Object.keys(props.cart).length }
                    </span>
        }

    const logout = e => {
        e.preventDefault();
        props.firebase.auth().signOut();
    }
    return (
        <div className="toolbar d-flex">
            <div
                className={ `toolbar-item visible-on-mobile mobile-menu-toggle ${ props.mobileMenuToggleActive ? "active" : "" }` }>
                <a href="#" onClick={ handleMobileMenuToggle } >
                    <div>
                        <i className="icon-menu"></i>
                        <span className="text-label">Menu</span>
                    </div>
                </a>
            </div>
            {
                props.currentClient ?
                <div className="toolbar-item hidden-on-mobile">
                    <a href="#" onClick={ (e) => { e.preventDefault() }  }>
                        <div>
                            <i className="icon-user"></i>
                            <span className="text-label">Mi Cuenta</span>
                        </div>
                    </a>
                    <div className="toolbar-dropdown text-center px-3">
                        <p className="text-xs mb-3 pt-2">
                            Bienvenido { props.currentClient.fullName == '.' ? "" : props.currentClient.fullName}
                        </p>                        
                        <Link className="btn btn-primary btn-sm btn-block"
                            to="/mi-cuenta/mis-datos">Mi Cuenta
                        </Link>
                        <a className="btn btn-secondary btn-sm btn-block"
                            href="/" onClick={ logout }>Salir
                        </a>
                    </div>
                </div>
                :
                <div className="toolbar-item hidden-on-mobile">
                    <a href="#" onClick={ (e) => { e.preventDefault() }  }>
                        <div>
                            <i className="icon-user"></i>
                            <span className="text-label">Login / Registro</span>
                        </div>
                    </a>
                    <div className="toolbar-dropdown text-center px-3">
                        <p className="text-xs mb-3 pt-2">
                            Ingresa para realizar compras y administrar tus pedidos.
                        </p>
                        <Link className="btn btn-primary btn-sm btn-block"
                            to="/login">Ingresar
                        </Link>
                    </div>
                </div>


            }
            
            <div className="toolbar-item">
                <Link to="/carrito">
                    <div>
                        <span className="cart-icon">
                            <i className="icon-shopping-cart"></i>
                            { badge }
                            
                        </span>
                        <span className="text-label">Carrito</span>
                    </div>
                </Link>
                <MiniCart />
            </div>
        </div>
    )
}

/*
const mapStateToProps = state => {

    console.log(state.cart)
    return {
        cart : state.cart
    }
}*/
const mapStateToProps = state => {
    return {
        firebase : state.firebase,
        currentClient : state.currentClient
    }
  }
  const mapDispatchToProps = { 
    setCurrentUser
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ToolbarHeader);
