import React, { Component } from 'react'
import { withRouter,  Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { setCurrentClient } from '../../../actions'

class MobileMenu extends Component {

    state = { 
        searchTerm : ''
    }

    constructor(props){
        super(props)
    }

    componentDidMount(){
        const params = new URLSearchParams(this.props.location.search)
        if(params.has("q"))
            this.setState({
                searchTerm : params.get("q")
            })
    }

    handleSearchTermChange(e){
        this.setState({
            searchTerm : e.target.value
        })
    }

    handleSearch(e){
        e.preventDefault();
        this.props.onSearch(this.state.searchTerm);
    }

    logout(e) {
        e.preventDefault();
        this.props.firebase.auth().signOut();
    }

    render() {
        return (
            <div className={ `mobile-menu ${ this.props.isActive ? "open" : "" }` } >
               <div className="mobile-search">
                    <form className="input-group" method="get" onSubmit={ this.handleSearch.bind(this) }>
                        <span className="input-group-btn">
                            <button type="submit"><i className="icon-search"></i></button>
                        </span>
                        <input className="form-control" type="search"
                            onChange={ this.handleSearchTermChange.bind(this) }
                            placeholder="Buscar productos..."/>
                    </form>
               </div>
               <div className="toolbar">

               {
                    this.props.currentClient ?
                    <>
                    <div className="toolbar-item"> 
                        <a href="#" onClick={ (e) => { e.preventDefault() }  }>
                            <div>
                                <i className="icon-user"></i>
                                <span className="text-label">Mi Cuenta</span>
                            </div>
                        </a>
                        
                    </div>      
                    <div className="toolbar-item">
                        <div className="text-center px-3">
                            <a className="btn btn-primary btn-sm btn-block"
                                href="/" onClick={ this.logout.bind(this) }>Salir
                            </a>
                        </div>
                    </div>              
                    </>
                    :                   
                    <div className="toolbar-item">
                        <Link to="/login" onClick={ (e) => { e.preventDefault() }  }>
                            <div>
                                <i className="icon-user"></i>
                                <span className="text-label">Login / Registro</span>
                            </div>
                        </Link>
                    </div>
                }

                </div>
                <nav className="slideable-menu">
                    <ul className="menu" data-initial-height="385">
                        <li className={ this.props.location.pathname === "/" ? "active" : "" }>
                            <span>
                                <Link to="/" onClick={ this.props.onItemClicked }>
                                    Inicio
                                </Link>
                            </span>
                        </li>
                        <li className={ 
                                this.props.location.pathname.startsWith("/producto") ? "active" : "" 
                            } >
                            <span>
                                <Link to="/productos"  onClick={ this.props.onItemClicked }>
                                    Productos
                                </Link>
                            </span>
                        </li>
                     {/*<li>
                        <span>
                            <Link to="/blog"  onClick={ this.props.onItemClicked }>
                                Blog
                            </Link>
                        </span>
                     </li>
                     <li>
                        <span>
                            <Link to="/nosotros"  onClick={ this.props.onItemClicked }>
                                Nosotros
                            </Link>
                        </span>
                     </li>
                    <li>
                        <span>
                            <a href="#"  onClick={ this.props.onItemClicked } >
                                Mi Cuenta
                            </a>
                        </span>
                    </li>*/}
                  </ul>
               </nav>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        firebase : state.firebase,
        currentUser : state.currentUser,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = { 
    setCurrentClient
};
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileMenu));

