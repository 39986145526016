import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';

import { connect } from 'react-redux'
import { addToCart, updateDiscounts } from '../../actions'
import { Link } from 'react-router-dom'
import Config from '../../Config'
import { formatNumber, strTofriendlyUrl, chooseDiscount, getDiscountsForArticle } from '../../utils'
import SeoComponent from '../../components/seo/SeoComponent'

import ImageNotFound from '../../img/image_not_found.png'

const SingleProduct = (props) => {

    const [stateObject, setStateObject] = useState({
        articulo: null,
        loading: true,
        modalIsShown : false,
        discountModalIsShown : false,
        addButtonEnabled : true,
        cantidadSeleccionada : 1,
        porcentajeDescuento : 0,
        selectedDiscount : null,
        descuentos : []
    })
    const { productoId } = props.match.params;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const url = `${Config.apiURL}api/articulo/${productoId}`

        const fetchData = async ()=>{
            
            try {

                const headers = {}
                if(props.currentClient)
                    headers.clienteid = props.currentClient.clienteId;  

                const response = await fetch(url, {
                    method: 'GET',
                    headers: headers
                });
                const jsonData = await response.json();
                const articulo = jsonData.articulo;
                let addToCartEnabled = false;
                let descuentos = [];
                let porcentajeDescuento = 0;
                let selectedDiscount = null;
                if(articulo) {
                    addToCartEnabled = !props.cart[articulo.articuloId] ||
                        props.cart[articulo.articuloId].cantidad + 
                        stateObject.cantidadSeleccionada <= articulo.cantidadDisponible
                    
                    descuentos = getDiscountsForArticle(jsonData.descuentos,
                        articulo.articuloId, articulo.familiaId);
                    
                    selectedDiscount = chooseDiscount(descuentos, 
                        1, props.precio);

                    porcentajeDescuento = selectedDiscount ? selectedDiscount.discount : 0;
                }
                props.updateDiscounts(jsonData.descuentos);
                setStateObject({
                    ...stateObject,
                    articulo: articulo,
                    descuentos: descuentos,
                    loading : false,
                    porcentajeDescuento : porcentajeDescuento,
                    selectedDiscount: selectedDiscount,
                    addButtonEnabled : addToCartEnabled
                })
            } catch (error) {
                console.log(error);
                    setStateObject({
                        articulo: null,
                        loading : false
                    })
            }

        }
        fetchData();

    }, [productoId, props.cartChangesCounter, props.currentClient])

    if (stateObject.loading) {
        return <div className="page-title">
                    <div className="container">
                        <div className="column">
                            Cargando...
                        </div>
                    </div>
                </div>
    }
    if (!stateObject.articulo) {
        return <div className="page-title">
                    <div className="container">
                        <div className="column">
                            Error al cargar el detalle del producto
                        </div>
                    </div>
                </div>
    }

    const { 
        fotografiaId, 
        descripcion, 
        familiaNombre, 
        familiaId,
        precioPorMenor,
        descripcionDetalle,
        articuloId,
        cantidadDisponible,
        descripcionDetalleHTML
    } = stateObject.articulo;

    const imageUrl = fotografiaId > 0 ? `${ Config.asistexaURL }image_${fotografiaId}_525_525.jpg` : ImageNotFound;
    const descripcionCorta =  descripcionDetalle.length > 150 ? descripcionDetalle.substring(0, 150) + "..." : descripcionDetalle;
    const relativeUrl = `producto/${articuloId}/${strTofriendlyUrl(descripcion)}`
    const descriptionForUrl = encodeURI(`Quisiera pedir este producto "${descripcion}"`)

    const whatsappPhoneNumber = props.configuracionSeo &&
        props.configuracionSeo.whatsappAtencionCliente && props.configuracionSeo.whatsappAtencionCliente != "" ?
        props.configuracionSeo.whatsappAtencionCliente : Config.whatsappForOrder

    const whatsappMessage = `https://api.whatsapp.com/send?phone=${ whatsappPhoneNumber }&text=${ descriptionForUrl }%20${ Config.currentURL }${ relativeUrl}`

    const handleClose = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault()
        setStateObject({
            ...stateObject,
            modalIsShown : false
        })
    }
    const cantidades = [];
    for(let i = 1; i <= cantidadDisponible; i++)
        cantidades.push(i)
    

    const addToStateObjectCart = (e) =>{
        e.preventDefault()
        console.log("Adding: " + stateObject.cantidadSeleccionada)
        const cart = props.cart;
        if(!cart[articuloId] || 
            cart[articuloId].cantidad + stateObject.cantidadSeleccionada <= cantidadDisponible){
            
            props.addToCart({
                articuloId : articuloId,
                familiaId : stateObject.articulo.familiaId,
                imagenUrl: imageUrl,
                precio : precioPorMenor,
                cantidad: stateObject.cantidadSeleccionada,
                nombreFamilia : familiaNombre, 
                nombreArticulo : descripcion,
                cantidadDisponible : cantidadDisponible
            })
    
            setStateObject({
                ...stateObject,
                modalIsShown : true,
                addButtonEnabled : true
            })
        }else{
            
            setStateObject({
                ...stateObject,
                addButtonEnabled : false
            })
        }
       

    }

    const handleDiscountModalClose = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault()
        setStateObject({
            ...stateObject,
            discountModalIsShown : false
        })
    }
    
    const handleShowDiscounts = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault()
        setStateObject({
            ...stateObject,
            discountModalIsShown : true
        })
    }
    
    const getDescripcionDetalle = () => {
        return {__html: descripcionDetalleHTML }
    }

    const handleQuantityChange = (e) =>{
        const nuevoValor = parseInt(e.target.value)
        
        const selectedDiscount = chooseDiscount(stateObject.descuentos, 
            nuevoValor, props.precio);
            
        const porcentajeDescuento = selectedDiscount ? selectedDiscount.discount : 0;

        const cart = props.cart;
        if(cart[articuloId] && 
            cart[articuloId].cantidad + nuevoValor > cantidadDisponible){

            setStateObject({
                ...stateObject,
                cantidadSeleccionada : nuevoValor,
                addButtonEnabled : false,
                selectedDiscount : selectedDiscount,
                porcentajeDescuento : porcentajeDescuento
            })
        
        
        }else{
            setStateObject({
                ...stateObject,
                cantidadSeleccionada : nuevoValor,
                addButtonEnabled : true,
                selectedDiscount: selectedDiscount,
                porcentajeDescuento : porcentajeDescuento
            })
        }
            
        
    }
    
    const precio = stateObject.articulo.precioPorMenor;
    const nuevoPrecio = precio * (1 - stateObject.porcentajeDescuento);

    let priceInUse = precio;

    let oldPrice = "";
    let currentPrice = `Bs. ${ formatNumber(precio) }`
    if(nuevoPrecio < precio){
        oldPrice = <del className="text-muted">{ currentPrice }</del>
        currentPrice = ` Bs. ${ formatNumber(nuevoPrecio) }`
        priceInUse = nuevoPrecio;
    }//


    return (
        <>
            <SeoComponent title={ descripcion }
                relativeUrl={ relativeUrl }
                description={ descripcionCorta }
                image={ imageUrl }
                productData={ {
                    imageUrl : imageUrl,
                    descripcion,
                    cantidadDisponible,
                    descripcionCorta,
                    codigo: stateObject.articulo.codigo,
                    precio: priceInUse
                } } />

            <Modal show={ stateObject.modalIsShown  } onHide={handleClose}>
                <Modal.Body>
                    <div className="alert alert-success text-center margin-bottom-1x">
                        <i className="icon-check-circle"></i>&nbsp;&nbsp;
                        Su producto ha sido agregado al carrito
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <a href="#" className="btn btn-secondary" onClick={handleClose}>
                        Continuar comprando
                    </a>
                    <Link to="/carrito" className="btn btn-primary">
                        Ir al Carrito
                        <i className="icon-arrow-right"></i>
                    </Link>
                </Modal.Footer>
            </Modal>
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>{descripcion}</h1>
                    </div>
                    <div className="column">
                        <ul className="breadcrumbs">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>
                                <Link to="/productos">Productos</Link>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>{ descripcion }</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x">
                <div className="row">
                    <div className="col-md-6">
                        <div className="product-gallery">
                            <div className="product-carousel owl-carousel gallery-wrapper"
                                style={ { display : "block"} }>
                                <div>
                                    <img src={imageUrl} alt={descripcion} />
                                </div>

                            </div>
                            <ul className="product-thumbnails">
                                <li className="active">
                                    <a href="#one">
                                        <img src={imageUrl} alt={descripcion} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="padding-top-2x mt-2 hidden-md-up"></div>
                        <div className="sp-categories pb-3">'
                            <i className="icon-tag"></i>
                            <Link to={ `/productos/categoria/${ familiaId }/${ strTofriendlyUrl(familiaNombre) }` }>{ familiaNombre }</Link>
                        </div>
                        <h2 className="mb-3">{descripcion}</h2>
                        <span className="h3 product-price-large">
                            { oldPrice }{ currentPrice }
                        </span>
                        <p className="text-muted">
                            { descripcionCorta }
                        </p>
                        {
                            stateObject.descuentos &&  stateObject.descuentos.length > 0 &&
                                <>
                                <div className="mb-3">
                                    <button className="btn btn-sm btn-danger" type="button"
                                        onClick={ handleShowDiscounts }>
                                        <i className="icon-dollar-sign"></i>
                                        &nbsp;&nbsp;
                                        Ver Ofertas
                                    </button>
                                </div>

                                <Modal show={ stateObject.discountModalIsShown  } onHide={ handleDiscountModalClose }>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Hemos encontrado estas ofertas para este producto</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Oferta</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stateObject.descuentos.map((item) => {
                                                        return (
                                                            <tr key={ item.articuloId }>
                                                                <td>
                                                                    Entre { item.cantidadMin }
                                                                    { item.cantidadMax < 0 ? ' o más' : ` y ${ item.cantidadMax }` }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.descuentoXPorcentaje ? 
                                                                           `${ formatNumber( item.porcentajeDescuento) }% de descuento` : 
                                                                           `${ formatNumber( item.precioDescuento) }Bs de descuento`

                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Modal.Body>
                                </Modal>
                                </>
                        }
                        <hr className="mb-2" />
                        {/*
                            selectedDiscount ? 
                            <div className="alert alert-warning  show text-justify margin-bottom-1x discount-alert">
                                <p className="text-center">
                                    <i className="icon-alert-triangle"></i>&nbsp;&nbsp;<span className="text-medium">Oferta:</span>
                                </p>
                                Lleva { selectedDiscount.info.cantidadMin } { " " }
                                { selectedDiscount.info.cantidadMax > selectedDiscount.info.cantidadMin ? "o más unidades " : "" }
                                y obtén { formatNumber(selectedDiscount.info.porcentajeDescuento) }% de descuento.
                                {
                                    selectedDiscount.info.cantidadMin != selectedDiscount.info.cantidadMax ? 
                                    <div>
                                        { `(Cantidad máxima por orden ${ selectedDiscount.info.cantidadMax } unidad${ selectedDiscount.info.cantidadMax > 0 ? "es" : "" } para optar por el descuento) ` }
                                    </div> 
                                    : "" 
                                }
                            </div>
                            : ""*/
                        }

                        {
                            cantidadDisponible > 0 ?
                            (<div className="row align-items-end pb-4">

                                <div className="col-sm-4">
                                    <div className="form-group mb-0">
                                        <label htmlFor="quantity">Cantidad</label>
                                        <select className="form-control" id="quantity"
                                            onChange={ handleQuantityChange }>
                                            {
                                                cantidades.map((item)=>{
                                                    return <option key={ item }
                                                                value={ item }>
                                                                { item }
                                                            </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="pt-4 hidden-sm-up"></div>
                                    <a href="#" onClick={ addToStateObjectCart }
                                        className="btn btn-primary btn-block m-0">
                                        <i className="icon-shopping-cart"></i>
                                        &nbsp;&nbsp;
                                        Agregar al carrito
                                    </a>
                                </div>

                                <div className={ `col-sm-12 mt-3${ stateObject.addButtonEnabled ? ' hidden' : '' }` }>
                                    <div className="alert alert-danger fade show text-center">
                                        <i className="icon-slash"></i>&nbsp;&nbsp;
                                        <span className="text-medium">No puede agregar mas items al carrito:</span>&nbsp;
                                        La cantidad seleccionada mas la que ya ha agregado al carrito de compras excede a la cantidad disponible.
                                    </div>
                                </div>
                            </div>)
                            :
                            (   
                                <div className="row align-items-end">
                                    <div className="col-sm-12 mt-3">
                                        <div className="alert alert-danger fade show text-center">
                                            <i className="icon-slash"></i>&nbsp;&nbsp;
                                            <span className="text-medium">El producto no está disponible en este momento</span>&nbsp;
                                            <div>
                                                Puede hacer su consulta vía Whatsapp acerca de la próxima disponibilidad 
                                                del producto.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="row align-items-end pb-4">
                            <div className="col-sm-12">
                                <div className="pt-4 hidden-sm-up"></div>
                                <a target="_blank"
                                    href={ whatsappMessage }
                                    className="btn btn-outline-success btn-block">
                                    <i className="socicon-whatsapp"></i>{ " " }
                                    Pedir por Whatsapp
                                </a>
                            </div>
                        </div>
                        <hr className="mb-2" />
                    </div>
                </div>
            </div>

            <div className="bg-secondary padding-top-3x padding-bottom-2x"
                id="details">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="h4">Detalle del Producto</h3>
                            <div className="mb-4"
                                dangerouslySetInnerHTML={ getDescripcionDetalle() }/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateObjectToProps = stateObject => {
    return {
        descuentos : stateObject.descuentos,
        cart : stateObject.cart,
        currentClient : stateObject.currentClient,
        cartChangesCounter: stateObject.cartChangesCounter,
        configuracionSeo: stateObject.configuracionSeo
    }
}
const mapDispatchToProps = { 
    addToCart,
    updateDiscounts
};

export default connect(mapStateObjectToProps, mapDispatchToProps)(SingleProduct);