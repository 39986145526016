import React from 'react'
import { withRouter } from 'react-router-dom';
import Logo from './components/Logo'
import Search from './components/Search'
import CategoriesMenu from './components/CategoriesMenu'
import ToolbarHeader from './components/ToolbarHeader'
import MobileMenu from './components/MobileMenu'
import MainMenu from './components/MainMenu'

class Header extends React.Component {
    state = {
        mobileMenuIsOpen : false
    }

    constructor(props){
        super(props);
    }

    handleSeach(searchTerm){
        this.props.history.push("/productos?q=" + encodeURIComponent(searchTerm))

        
    }

    handleMobileMenu(){
        const isOpen = !this.state.mobileMenuIsOpen;
        this.setState({
            mobileMenuIsOpen : isOpen
        })
        return isOpen;
    }

    render(){
        return (
            <header className="site-header">
                <div className="topbar d-flex justify-content-between">
                    <Logo />
                    <div className="search-box-wrap d-flex">
                        <div className="search-box-inner align-self-center">
                            <div className="search-box d-flex">
                                <CategoriesMenu categorias={ this.props.categorias } />
                                <Search onSearch={ this.handleSeach.bind(this) } />
                            </div>
                        </div>
                    </div>
                    <ToolbarHeader onClickInMobileMenu={ () => { this.handleMobileMenu() } } />
                    <MobileMenu isActive={ this.state.mobileMenuIsOpen }
                        onSearch={ this.handleSeach.bind(this) } />
                </div>
                <div className="navbar">
                    <MainMenu />
                </div>
            </header>
        )
    }
}

export default withRouter(Header);