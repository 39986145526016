import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class NotFound extends Component {
    render() {
        return (
            <section className="fw-section margin-top-3x not-found">
                <h1 className="display-404 text-center">404</h1>
                <div className="text-center mb-3">
                    <h2>Página no encontrada</h2>
                    <p>
                        Al parecer la página que esta buscando no existe o no está disponible.<br/><br/>
                        <Link to="/" className="btn btn-primary">Ir a la página de Inicio</Link><br/>
                        o trate de usar el buscador de la parte superior.
                    </p>
                </div>
            </section>
        )
    }
}
