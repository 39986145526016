import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'


import { formatNumber } from '../../utils'
import Config from '../../Config'

const PagoQR = (props) => {

    const { ventaId } = props.match.params;

    const [ currentAction, setCurrentAction ] = useState("getOrder");
    const [ currentOrder, setCurrentOrder ] = useState(null);
    const [ counter, setCounter ] = useState(0);
    const [ currentPayment, setCurrentPayment ] = useState(null);


    const getOrder = () => {
        const url = Config.apiURL + 'api/pedido/' + ventaId;

        const headers = {}
        
        const token = localStorage.getItem("token");
        if(token)
            headers.Authorization = `Bearer ${ token }`

        fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then(json =>{

                if(json && json.success && json.pedido){
                    const obj = json.pedido;
                    setCurrentOrder(obj)
                    if(obj.estadoVentaId != 4){
                        setCurrentAction("showOrderNoPayable");
                        return;
                    }
                    setCurrentAction("getPendingPayment");
                }else{
                    
                    setCurrentAction("showOrderNoPayable");
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getPendingPayment = () =>{
        const url = Config.apiURL + 'api/pedido/deposito/' + ventaId;

        const headers = {}
        
        const token = localStorage.getItem("token");
        if(token)
            headers.Authorization = `Bearer ${ token }`

        fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then(json =>{
                if(json && json.success && json.deposito){
                    const obj = json.deposito;
                    
                    if(obj.pagado)
                        props.history.push("/pedido-finalizado")
                    else{
                        if(currentAction == "getPendingPayment")
                            setCurrentPayment(obj);
                        setCurrentAction("checkPayment");
                        setCounter(counter + 1);
                    }
                    return;
                }
                
                setCurrentAction("showOrderNoPayable");
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {

        if(!currentAction)
            return;

        if(currentAction == "getOrder"){
            getOrder();
            return;
        }

        if(currentAction == "getPendingPayment"){
            getPendingPayment();
            return;
        }

        let timer = null;
        
        if(currentAction == "checkPayment"){
            timer = setTimeout(() => {
                getPendingPayment();
            }, 1000);
        }
        return () => {
            if(timer) 
                clearTimeout(timer);
        }

    },[currentAction,counter])

    if(!ventaId || ventaId == "")
        props.history.push("/404")

    
    if(currentAction == "getOrder" || currentAction == "getPendingPayment"){
        return (
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Cargando...</h1>
                    </div>
                </div>
            </div>
        )

    }

    if(currentAction == "showOrderNoPayable"){

        return (
            <>
                <div className="page-title">
                    <div className="container">
                        <div className="column">
                            <h1>El pago del pedido no puede ser procesado</h1>
                        </div>
                    </div>
                </div>

                <div className="container padding-bottom-3x mb-2">
                    <div className="row">
                        <div className="offset-md-3 col-md-6 ">
                            <div className="card"
                                >
                                <div className="card-body">
                                    <h4 className="margin-bottom-1x">El pedido 
                                        { ` ${ currentOrder.codigo }`} ya se encuentra 
                                        pagado
                                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )


    }

    return (
        <>
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>
                            Pago del Pedido { currentOrder.codigo }
                        </h1>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <div className="offset-md-3 col-md-6 ">
                        <div className="card"
                            >
                            <div className="card-body">
                                    
                                <div className="alert alert-warning fade show text-center margin-bottom-1x">
                                    <strong>
                                        <i className="icon-alert-triangle"></i>
                                        &nbsp;&nbsp;
                                        Por favor no cierre esta ventana.
                                    </strong>
                                </div>
                                <h4 className="text-center">
                                    Monto a Pagar: 
                                    &nbsp;&nbsp;
                                    <strong>Bs. { " "}
                                        { formatNumber(currentOrder.montoTotalExtras) }
                                    </strong>
                                </h4>
                                <p className="text-justify">
                                    Por favor ingrese a la aplicación de su Banca Movil,
                                    ingrese a la opción <strong>Transferencia Simple QR</strong>,
                                    seleccione la opción de <strong>Pagar</strong> y luego escanee el
                                    siguiente código QR:
                                </p>
                                <div className="text-center">
                                    <img src={ `${ Config.asistexaURL }image_${ currentPayment.fileId }_300_300.jpg` } 
                                        alt="" />
                                </div>
                                <p className="text-center mt-1">
                                    Comprobando pago...
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default withRouter(PagoQR);
