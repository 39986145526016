import React from 'react'
import { Link } from 'react-router-dom'
import Config from '../../Config'

const About = () => {
    return (
        <>
        <div className="page-title">
            <div className="container">
                <div className="column">
                    <h1>Sol Adventures</h1>
                </div>
                <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Inicio</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Acerca de Sol Adventures</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="container pb-5">
            <p>
                Sol Adventures es una tienda de demostración para el sistema Asistexa.
                Sol Adventures no existe como empresa, pero puede ser utilizada para probar
                la funcionalidad de tienda electrónica de Asistexa.
            </p>
            <p>
                Asistexa es una solución completa para la administración de inventarios y ventas,
                incluyendo manejo de almacenes, inventarios, ventas en tiendas físicas y ventas
                en-linea.   Asistexa tiene todas las funciones necesarias para gestionar una tienda,
                incluyendo manejo de clientes, créditos, descuentos, cupones o gift-cards y
                herramientas de inteligencia de negocio que le permiten optimizar sus ventas.
            </p>
            <p>
                Más información en <a href="http://www.asistexa.com"  rel="noopener noreferrer" target="_blank">http://www.asistexa.com</a>
            </p>
        </div>
        </>
    )
}
export default About