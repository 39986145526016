import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { strTofriendlyUrl } from '../../../utils'

const BrandsMenu = (props) => {

    return (
        <section className="widget widget-categories">
            <h3 className="widget-title">Buscar por Marcas</h3>
            <ul>
                {
                    props.marcas.length == 0 ?

                        <div>No se encontraron marcas</div>
                    :
                    props.marcas.map((marca, index) => (


                        <li key={ marca.marcaId }>
                            <Link className={ marca.marcaId == props.marcaActual ? "text-bold" : "" }
                                to={ `/productos/marca/${ marca.marcaId }/${strTofriendlyUrl(marca.descripcion)}` }>
                                { marca.descripcion }
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </section>
    )
}

export default BrandsMenu
