const Config = {
    apiURL: "https://twiggy.com.bo/",
    pageSize: 12,
    asistexaURL: "https://asistexa.twiggy.com.bo/",
    currentURL: "https://twiggy.com.bo/",
    whatsappForOrder: "+59176753100",
    gpsDefaultPosition: {
        lat: -16.5422237,
        lng: -68.0826703
    },         
    GoogleMapApiKey: 'AIzaSyACQ-j10gQVtiERL-kXrT_G6DcJ07RCMSM',
    siteName: 'Twiggy Perfumer�a',
    siteDescription: "Perfumes de renombre internacional, productos de cuidado personal y tratamientos de belleza en Bolivia",
    slideshowBackgroundEnabled: false
}
export default Config

