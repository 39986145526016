import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { withRouter } from 'react-router'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';

const GAComponent = ({ gaID, history }) => {

    useEffect(() => {
        if(!gaID)
            return;

        if (gaID.startsWith("GTM")) {
            const tagManagerArgs = {
                gtmId: gaID
            }
            TagManager.initialize(tagManagerArgs)
            history.listen(location => {
                console.log("Visiting... " + location.pathname)
                window.dataLayer.push({
                    event: 'pageview'
                });
            });
            const timer = setTimeout(() => {
                window.dataLayer.push({
                    event: 'pageview'
                });
            }, 1200);
            return () => clearTimeout(timer);
        }
        ReactGA.initialize(gaID);
        ReactGA.pageview(window.location.pathname)
        history.listen(location => {
                console.log("Visiting... " + location.pathname)
                ReactGA.pageview(location.pathname)
            }                    
        );

        
    }, [gaID])

    return (
        <></>
    )
}


const mapStateObjectToProps = stateObject => {
    return {
        gaID : stateObject.gaID
    }
}
const mapDispatchToProps = null;

export default connect(mapStateObjectToProps, mapDispatchToProps)(withRouter(GAComponent));
