import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { strTofriendlyUrl } from '../../../utils'
 
function CategoriesMenu({ categorias, categoriaActual, subCategoriaActual }){

    
    return (
        <section className="widget widget-categories">
            <h3 className="widget-title">Buscar por Categorias</h3>
            <ul>
                { 
                    categorias.length == 0 ?

                    <div>No se encontraron categorias</div>
                    :
                    categorias.map((categoria, index)=> (


                        <li className={ categoria.tipos.length > 0 ? "has-children expanded" : "" }
                            key={categoria.familiaId}>
                            <Link className={ categoria.familiaId == categoriaActual ? "text-bold" : "" }
                                to={ `/productos/categoria/${ categoria.familiaId }/${strTofriendlyUrl(categoria.nombre)}` }>
                                { categoria.nombre }
                            </Link>
                            {
                                categoria.tipos.length > 0 &&
                                    <ul>
                                        {
                                            categoria.tipos.map((tipo) => (
                                                <li key={ tipo.tipoId }>
                                                    <Link className={ tipo.tipoId == subCategoriaActual ? "text-bold" : "" }
                                                        to={ `/productos/subcategoria/${tipo.tipoId}/${strTofriendlyUrl(tipo.nombre)}` }>
                                                        { tipo.nombre }
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                            }

                        </li>
                    ))
                }
            </ul>
        </section>
    )
}

export default CategoriesMenu
