import React from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CartItem = (
    { 
        onRemoveItem,
        onChangeQuantity,
        nombreArticulo,
        subTotalForDisplay,
        precioEnUsoForDislay,
        precioForDislay,
        precio,
        precioEnUso,
        articuloId,
        cantidad,
        cantidadDisponible,
        imagenUrl,
        friendlyNameUrl
    }) => {

    const removeItem = (e) =>{
        e.preventDefault();
        onRemoveItem({
            articuloId
        });
    }

    const updateQuantity = (e) =>{
        onChangeQuantity({
            articuloId,
            cantidad : parseInt(e.target.value)
        });
    }

    const cantidades = [];
    for(let i = 1; i <= cantidadDisponible; i++)
        cantidades.push(i)

    let oldPrice = "";
    if(precioEnUso < precio)
        oldPrice = <span className="text-muted"><em>Precio antes:</em> <del>Bs. { precioForDislay } </del></span>
        
    return (
        <tr>
            <td>
                <div className="product-item">
                    <Link className="product-thumb" to={ `/producto/${ articuloId }/${ friendlyNameUrl }` }>
                        <img src={ imagenUrl } alt={ nombreArticulo }/>
                    </Link>
                    <div className="product-info">
                        <h4 className="product-title">
                            <Link to={ `/producto/${ articuloId }/${ friendlyNameUrl }` }>
                            { nombreArticulo }
                            </Link>
                        </h4>
                        { oldPrice }
                        <span><em>Precio:</em> Bs. { precioEnUsoForDislay }</span>
                    </div>
                </div>
            </td>
            <td className="text-center">
                <div className="count-input">
                    <select className="form-control" value={ cantidad }
                        onChange={ updateQuantity }>
                        {
                            cantidades.map((item)=>{
                                return <option key={ item }
                                            value={ item }>
                                            { item }
                                        </option>
                            })
                        }
                    </select>
                </div>
            </td>
            <td className="text-center text-lg">Bs. { subTotalForDisplay }</td>
            <td className="text-center">
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        Quitar del Carrito
                    </Tooltip>
                }
                >
                <a className="remove-from-cart" href="#"  onClick={ removeItem }
                    data-toggle="tooltip" title=""
                    data-original-title="Remove item">
                        <i className="icon-x"></i>
                </a>
            </OverlayTrigger>



                
            </td>
        </tr>
    )
}

export default CartItem;
