import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { removeFromCart, clearCart, updateCart, updateDiscounts } from '../../actions'
import { formatNumber, strTofriendlyUrl } from '../../utils'

import SeoCompoment from '../../components/seo/SeoComponent'
import Config from '../../Config'
import CartItem from './components/CartItem'

const Cart = (props) => {

    useEffect(() => {
        const url = `${Config.apiURL}api/articulo/0`
        fetch(url)
            .then(res => res.json())
            .then((jsonData) => {
                props.updateDiscounts(jsonData.descuentos)
            })
    })

    let total = 0;
    const cartItems = [] 
    for (const id in props.cart) {
        const articulo = props.cart[id];
        if(!articulo.precioEnUso)
            articulo.precioEnUso = articulo.precio;
        cartItems.push(articulo);
        total += articulo.precioEnUso * articulo.cantidad
    }

    const clearCart = (e) =>{
        e.preventDefault();
        props.clearCart();
    } 

    const title = <div className="page-title">
        <div className="container">
            <div className="column">
                <h1>Carrito de Compras</h1>
            </div>
            <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Inicio</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Carrito de Compras</li>
                </ul>
            </div>
        </div>
    </div>

    if (cartItems.length == 0){

        return (
            <>
                { title }

                
                <div className="container padding-bottom-3x mb-1 text-center">

                    <i className="icon-shopping-bag" style={ { fontSize : "80px" } }></i>
                    <br/><br/>
                    <div className="mb-3">
                        Actualmente su carrito de compras está vacio. Puede ir al 
                        listado de productos y seleccionar los productos que desee agregar 
                        a su pedido
                    </div>
                    <Link to="/productos" className="btn btn-primary">
                        Ir al Listado de Prouctos
                    </Link>


                </div>
            </>

        )

    }

    return (
        <>
            <SeoCompoment title="Carrito de Compras"
                relativeUrl={ "carrito "}
                description={Config.siteDescription} />
            { title }

            <div className="container padding-bottom-3x mb-1">

                <div className="table-responsive shopping-cart">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre del Producto</th>
                                <th className="text-center">Cantidad</th>
                                <th className="text-center">Subtotal</th>
                                <th className="text-center">
                                    <a className="btn btn-sm btn-outline-danger"
                                        href="#"
                                        onClick={ clearCart }>
                                        Vaciar Carrito
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            cartItems.map((articulo) => {
                                return  (
                                    <CartItem key={ articulo.articuloId }
                                        onRemoveItem={ props.removeFromCart }
                                        onChangeQuantity={ props.updateCart }
                                        nombreArticulo={ articulo.nombreArticulo }
                                        friendlyNameUrl={ strTofriendlyUrl(articulo.nombreArticulo) }
                                        articuloId={ articulo.articuloId }
                                        cantidad={ articulo.cantidad }
                                        precioEnUso={ articulo.precioEnUso }
                                        precio={ articulo.precio }
                                        cantidadDisponible={ articulo.cantidadDisponible }
                                        subTotalForDisplay={ formatNumber(articulo.precioEnUso * articulo.cantidad) }
                                        precioForDislay={ formatNumber(articulo.precio) }
                                        precioEnUsoForDislay={ formatNumber(articulo.precioEnUso) }
                                        imagenUrl={ articulo.imagenUrl } />
                                    
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className="shopping-cart-footer">
                    <div className="column text-lg">
                        <span className="text-muted">
                            Total:&nbsp;
                        </span>
                        <span className="text-gray-dark">
                            Bs. { formatNumber(total) }
                        </span>
                    </div>
                </div>

                <div className="shopping-cart-footer">
                    <div className="column">
                        <Link className="btn btn-outline-secondary"
                            to="/productos">
                            <i className="icon-arrow-left"></i>&nbsp;Volver a la Tienda
                        </Link>
                    </div>
                    <div className="column">
                        <a href="/checkout" className="btn btn-primary">Realizar Pedido</a>
                    </div>
                </div>

            </div>

        </>
    )
}

const mapStateToProps = state => {
    return {
        cart : state.cart,
        cartChangesCounter: state.cartChangesCounter
    }
}
const mapDispatchToProps = {
    removeFromCart,
    clearCart,
    updateCart,
    updateDiscounts
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
