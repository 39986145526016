import React, { Component} from 'react'
import { withRouter } from 'react-router-dom'


class Search extends Component {
    state = { 
        searchTerm : ''
    }

    componentDidMount(){
        const params = new URLSearchParams(this.props.location.search)
        if(params.has("q"))
            this.setState({
                searchTerm : params.get("q")
            })
    }

    handleSearchTermChange(e){
        this.setState({
            searchTerm : e.target.value
        })
    }

    handleSearch(e){
        e.preventDefault();
        this.props.onSearch(this.state.searchTerm);
    }

    render() {
        return (
            <form className="input-group" method="get" onSubmit={ this.handleSearch.bind(this) } >
                <span className="input-group-btn">
                    <button type="submit" >
                        <i className="icon-search"></i>
                    </button>
                </span>
                <input className="form-control" type="search" value={ this.state.searchTerm }
                    onChange={ this.handleSearchTermChange.bind(this) }
                    placeholder="Buscar productos..."/>
            </form>

        )
    }
}

export default withRouter(Search)
