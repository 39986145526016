import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearCart } from '../../actions'

import CheckoutSteps from './components/CheckoutSteps'
import GeneralInformation from './components/GeneralInformation'
import Shipping from './components/Shipping'
import Payment from './components/Payment'
import ReviewOrder from './components/ReviewOrder'
import SeoCompoment from '../../components/seo/SeoComponent'
import Config from '../../Config'
import { formatNumber } from '../../utils'

const Checkout = ({ cart, clearCart, history, currentClient, configuracionSeo }) => {

    const [ makingOrder, setMakingOrder ] = useState(false);
    const [ currentSection, setCurrentSection ] = useState(1)
    const [ generalInformation, setGeneralInformation ] = useState({
        nombreCompleto : currentClient.fullName,
        telefono : currentClient.telefono,
        razonSocial : "",
        nit : ""
    })
    const [ costoEnvio , setCostoEnvio ] = useState({
        estado : "NoCalculado",
        costo: 0
    });
    const [ paymentType, setPaymentType ] =  useState("ContraEntrega");
    const [ shipping, setShipping ] = useState({
        address: "",
        city: "",
        cityName: "",
        latitud: configuracionSeo.latitud == 0 ? Config.gpsDefaultPosition.lat : configuracionSeo.latitud,
        longitud: configuracionSeo.longitud == 0 ? Config.gpsDefaultPosition.lng : configuracionSeo.longitud,
        requestGeolocation : true
    });

    let subtotal = 0;
    for(let i in cart){
        subtotal += cart[i].precioEnUso * cart[i].cantidad;
    }

    const handleSectionChanged = (selectedSection) => {
        setCurrentSection(selectedSection)
    }

    const goToSecondSection = (generalInformation) => {
        setGeneralInformation(generalInformation);
        setCurrentSection(2);
    }

    const goToThirdSection = (shipping) =>{
        setShipping(shipping);
        setCurrentSection(3)
    }

    const changeCostoEntrega = (objCostoEnvio) => {
        setCostoEnvio(objCostoEnvio)
    }

    const handlePaymentSelection = () =>{
        setCurrentSection(4)

    }

    const onChangePaymentType = (paymentType) => {
        setPaymentType(paymentType)
    }

    const handleFinishOrder = () =>{

        if(makingOrder)
            return;
        setMakingOrder(true)
        const data = {
            nombreCliente : generalInformation.nombreCompleto,
            telefono: generalInformation.telefono,
            razonSocial : generalInformation.razonSocial,
            nit:generalInformation.nit,
            //ciudadId : isNaN(shipping.city) ? 1 : parseInt(shipping.city),
            nombreCiudad : shipping.cityName,
            direccion: shipping.address,
            latitud: shipping.latitud,
            longitud: shipping.longitud,
            costoEntrega: costoEnvio.costo,
            clienteId : currentClient.clienteId,
            tipoPago : paymentType
        }

        const cartItems = [] 
        for (const id in cart) {
            const articulo = cart[id];
            articulo.descuento = (1 - articulo.precioEnUso / articulo.precio) * 100.0; 
            cartItems.push(articulo);
        }

        data.detalle = cartItems

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
        
        const token = localStorage.getItem("token");
        if(token)
            headers.Authorization = `Bearer ${ token }`

        fetch(`${Config.apiURL}api/pedido`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(json => {
            if(json  &&  json.success){
                clearCart();
                if(paymentType == "PagoQR"){
                    history.push(`/pago-qr/${ json.ventaId }`)
                }else{
                    history.push("/pedido-finalizado")
                }
                
            }else{
                
                setMakingOrder(false);
            }
        }).catch(err => {
            console.log(err);
            setMakingOrder(false);
            alert("No se pudo registrar el pedido. Por favor, intente nuevamente.")
        });
    }

    return (
        <>
            
            <SeoCompoment title="Realizar Pedido"
                relativeUrl={"checkout "}
                description={Config.siteDescription} />
            <div className="page-title">
                <div className="container">
                    <div className="column">
                    <h1>Realizar Pedido</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li>
                            <Link to="/">Inicio</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Realizar Pedido</li>
                    </ul>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <div className="col-xl-9 col-lg-8">
                        <CheckoutSteps currentSection={ currentSection }
                            onSectionChanged={ handleSectionChanged } />

                        {
                            currentSection == 1 &&
                                (
                                    <GeneralInformation nombreCompleto={ generalInformation.nombreCompleto }
                                        telefono={ generalInformation.telefono }
                                        nit={ generalInformation.nit }
                                        razonSocial={ generalInformation.razonSocial }
                                        onNextSection={ goToSecondSection } />
                                )                            
                        }
                        {
                            currentSection == 2 &&
                                (
                                    <Shipping 
                                        changeCostoEnvio={ changeCostoEntrega }
                                        position={ { lat: shipping.latitud, lng: shipping.longitud } }
                                        address={ shipping.address }
                                        city={ shipping.city }
                                        costoEnvio={ costoEnvio }
                                        cityName={ shipping.cityName }
                                        onBackButton={ () => { setCurrentSection(1) } }
                                        onNextSection={ goToThirdSection }
                                        requestGeolocation={ shipping.requestGeolocation } />
                                )                            
                        }
                        {
                            currentSection == 3 &&
                                (
                                    <Payment
                                        onChangePaymentType={ onChangePaymentType }
                                        selectedPaymentType={ paymentType }
                                        onBackButton={ () => { setCurrentSection(2) } }
                                        onNextSection={ handlePaymentSelection } />
                                )                            
                        }

                        {
                            currentSection == 4 &&
                                (
                                    <ReviewOrder
                                        makingOrder={ makingOrder }
                                        cart={ cart }
                                        paymentType={ paymentType }
                                        shipping={ shipping }
                                        generalInformation={ generalInformation }
                                        onBackButton={ () => { setCurrentSection(3) } }
                                        onCompleteOrder={ handleFinishOrder } />
                                )                            
                        }
                    </div>
                
                    <div className="col-xl-3 col-lg-4">
                        <aside className="sidebar">
                            <div className="padding-top-2x hidden-lg-up"></div>
                            <section className="widget widget-order-summary">
                                <h3 className="widget-title">Resumen</h3>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Subtotal:</td>
                                            <td className="text-gray-dark">Bs. { formatNumber(subtotal)}</td>
                                        </tr>
                                        <tr>
                                            <td>Costo de Envío:</td>
                                            <td className="text-gray-dark">
                                                {
                                                    costoEnvio.estado == "NoCalculado" ? "--" :
                                                    costoEnvio.estado == "NoPosible" ? <span className="text-danger">Entrega no disponible</span> :
                                                    `Bs. ${ formatNumber(costoEnvio.costo)}`
                                                }


                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className="text-lg text-gray-dark">Bs. { formatNumber(subtotal + costoEnvio.costo)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        </aside>
                    </div>
                </div>
            </div>

            
        </>
    )
}

const mapStateToProps = state => {
    return {
        cart : state.cart,
        currentClient : state.currentClient,
        configuracionSeo: state.configuracionSeo
    }
}
const mapDispatchToProps = { 
    clearCart
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Checkout));
