import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Config from '../../../Config';
import LogoImage from '../../../img/logo/logo.png'

function Logo(props) {

    const logoFileId = props.configuracionSeo && props.configuracionSeo.logoFileId &&
        props.configuracionSeo.logoFileId ? props.configuracionSeo.logoFileId : 0;

    const logo = logoFileId > 0 ? 
        `${Config.asistexaURL}image_${logoFileId}_270_91.jpg` :
        LogoImage;

    return (
        <div className="site-branding d-flex">
            <Link className="site-logo align-self-center" to="/">
                <img src={ logo } alt="Unishop"/>
             </Link>
        </div>  
    )
}


const mapStateToProps = state => {
    return {
        configuracionSeo: state.configuracionSeo
    }
}
const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Logo);