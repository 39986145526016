import React, { useState, useEffect } from 'react'
import Config from '../../Config'

const EmailVerification = (props) => {

    const queryStringParams = new URLSearchParams(props.location.search);
    const code = queryStringParams.has("code") ? encodeURIComponent(queryStringParams.get("code")) : null;

    const [ loading, setLoading ] = useState(true);
    const [ isOk, setIsOk ] = useState(false);

    useEffect(() => {
        if(!code || code == ""){
            setLoading(false);
            return;
        }

        const data = {
            "ValidationCode" : code
        }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }

        const url =  `${ Config.apiURL }api/cliente/validate-email`
        fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(jsonResult => {
            
            if(jsonResult && jsonResult.success){
                setIsOk(true);
            }else{
                setIsOk(false);
            }
            
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setLoading(false);
            setIsOk(false);

        });
        

        
    }, [])


    if (loading) {

        return (
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Verificando...</h1>
                    </div>
                </div>
            </div>
        )
    }

    const message = (!code || code == "") ?
        "La solicitud de verificación es inválida" :
        isOk ? "La verificación se ha realizado correctamente." :
              "La verificación no ha podido ser realizada."
               

    return (
        <>
        <div className="page-title">
            <div className="container">
                <div className="column">
                    <h1>Verificación de Correo Electrónico</h1>
                </div>
            </div>
        </div>


        <div className="container padding-bottom-3x mb-2">
            <div className="row">
                <div className="offset-md-3 col-md-6 ">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="margin-bottom-1x">
                                { message }
                            </h4>
                            <div>
                                <a className="btn btn-secondary" href="/">Continuar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default EmailVerification
