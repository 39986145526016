import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';

import AccountMenu from './components/AccountMenu'
import { formatNumber, formatDate, strTofriendlyUrl } from '../../utils'
import Config from '../../Config'
import SeoComponent from '../../components/seo/SeoComponent'


const Orders = ({ currentClient }) => {

    const [ currentOrder, setCurrentOrder ] = useState({
        codigo : '',
        detalle: [],
        pagoTotal : 0,
        costoTransporte: 0
    });

    const [ showDetails, setShowDetails ] = useState(false)

    const [ data, setData ] = useState({
        successLoaded : true,
        pedidos : []
    });
    
    const [ loading, setLoading ] = useState(false)
 
    useEffect(() => {
       setLoading(true);

       const url = Config.apiURL + 'api/pedido/cliente/' + currentClient.clienteId;
       const headers = {}
        
       const token = localStorage.getItem("token");
       if(token)
           headers.Authorization = `Bearer ${ token }`

       fetch(url, {
           method: 'GET',
           headers: headers
       })
            .then(response => response.json())
            .then(json =>{
                setData({
                    successLoaded : json.success,
                    pedidos : json.pedidos
                })
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setData({
                    successLoaded : false,
                    pedidos : []
                })
            })

    }, [])

    const handleDetails = (e) => {
        e.preventDefault();
        debugger;
        const id = e.target.dataset["id"];

        const url = Config.apiURL + 'api/pedido/' + id;

        const headers = {}
        
        const token = localStorage.getItem("token");
        if(token)
            headers.Authorization = `Bearer ${ token }`

        fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then(json =>{
                setCurrentOrder(json.pedido);
                setShowDetails(true)
            })
            .catch(err => {
                console.log(err);
            })
    }

    
    const handleClose = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault();
        setShowDetails(false);
    }

    const getEstado = (estadoVentaId, ventaId) => {
        switch(estadoVentaId){
            case 1 :                                                               
                return <span className="text-warning">Su pedido esta siendo preparado por la Tienda </span>

            case 2 : 
                return <span className="text-success">Entregado</span>
            case 3 : 
                return  <span className="text-danger">Cancelado</span>
            case 4 : 
                return <span className="text-muted">Pendiente de Pago</span>
            case 5 : 
                return <>
                            <span className="text-info">En Camino</span>
                            <Link to={ `/entrega/${ ventaId }` }
                                className="btn btn-block btn-secondary">
                                Ver Seguimiento de Pedido
                            </Link>
                        </>
        }
        return "No Definido"
            
    }

    return (
        <>
            <SeoComponent title="Mis Pedidos" />
            <Modal show={ showDetails  } onHide={ handleClose } dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <h4 className="modal-title">Pedido { currentOrder.codigo }</h4>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className="table-responsive shopping-cart mb-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th className="text-center">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentOrder.detalle.map(item => {


                                        return <tr key={ item.articuloId }>
                                                <td>
                                                <div className="product-item">
                                                    
                                                    <div className="product-info">
                                                        <h4 className="product-title">
                                                            <Link to={ `/producto/${ item.articuloId }/${ strTofriendlyUrl(item.descripcionConcepto) }` }>
                                                            { item.descripcionConcepto }
                                                            </Link>
                                                        </h4>
                                                        <span><em>Precio: </em> Bs. { formatNumber(item.precioVenta) }</span>                                                        
                                                        <span><em>Cantidad: </em> { item.cantidad }</span>
                                                        {
                                                            item.descuento && item.descuento > 0 ?
                                                            <span><em>Descuento: </em> Bs. { formatNumber(item.descuento) }</span> : ""
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                </td>
                                                <td className="text-center text-lg">{ formatNumber((item.precioVenta  * item.cantidad) - item.descuento) }</td>
                                            </tr>
                                    })

                                }
                                
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                <div className="d-flex flex-wrap justify-content-between align-items-center pb-2" style={ { width: "100%" } }>
                    <div className="px-2 py-1">
                        <span className="text-muted">Subtotal: </span> 
                        <span className="text-gray-dark">{ formatNumber(currentOrder.pagoTotal) }</span>
                    </div>
                    <div className="px-2 py-1">
                        <span className="text-muted">Costo de Envío: </span>
                        <span className="text-gray-dark">{ formatNumber(currentOrder.costoTransporte) }</span>
                    </div>
                    <div className="px-2 py-1">&nbsp;</div>
                    <div className="text-lg px-2 py-1">
                        <span className="text-muted">Total: </span>
                        <span className="text-gray-dark">{ formatNumber(currentOrder.pagoTotal + currentOrder.costoTransporte) }</span>
                    </div>
                </div>
                </Modal.Footer>
            </Modal>


            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Mis Pedidos</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>
                            Mi Cuenta
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Mis Pedidos</li>
                    </ul>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <AccountMenu currentPage="orders" />
                    <div className="col-lg-9">
                        
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr className="text-center">
                                        <th>Pedido</th>
                                        <th>Datos</th>
                                        <th>Estado</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ? 
                                        <tr>
                                            <td colSpan="4" className="text-center">Cargando Pedidos</td>
                                        </tr>
                                        :
                                        data.successLoaded ?
                                            data.pedidos.length > 0 ?
                                                data.pedidos.map(item => {
                                                    return (
                                                        <tr key={ item.ventaId }>
                                                            <td className="text-center pt-3">
                                                                { item.codigo }
                                                                <div>
                                                                    <input type="button" className="btn btn-secondary btn-block"
                                                                        data-id={ item.ventaId }
                                                                        onClick={ handleDetails }
                                                                        value="Ver Detalle" />
                                                                </div>
                                                            </td>
                                                            <td className="pt-3">
                                                                <div className="mb-2">
                                                                    <strong>Fecha del Pedido:</strong> { formatDate(new Date(item.fechaRegistro)) }
                                                                </div>
                                                                <div className="mb-2">
                                                                    <strong>Entregar a:</strong> {item.nombreClienteEntrega}
                                                                </div>
                                                                <div className="mb-2">
                                                                    <strong>Teléfono:</strong> {item.telefonoEntrega}
                                                                </div>
                                                                <div className="mb-2">
                                                                    <strong>Razon Social:</strong> { item.razonSocial }
                                                                </div>
                                                                <div className="mb-2">
                                                                    <strong>NIT:</strong> { item.nit }
                                                                </div>
                                                                <div className="mb-2">
                                                                    <strong>Entrega:</strong> { item.observacionesEntrega }
                                                                </div>
                                                            </td>
                                                            <td className="text-center pt-3">
                                                                {
                                                                    getEstado(item.estadoVentaId, item.ventaId)
                                                                }
                                                            </td>
                                                            <td className="text-right pt-3">Bs. { formatNumber(item.pagoTotal + item.costoTransporte) }</td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr>
                                                    <td colSpan="5" className="text-center">
                                                        Aún no tiene pedidos registrados
                                                        <div>
                                                            <Link to="/productos" className="btn btn-secondary">
                                                                Explorar productos
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                        :
                                        <tr>
                                            <td colSpan="5" className="text-center text-danger">Ocurrio un error al cargar los pedidos</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        firebase : state.firebase,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(Orders);