import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import Config from '../../../Config'
import { strTofriendlyUrl } from '../../../utils'

const CategoriesMenu = ({ categorias }) => {
/*
    const [state, setState] = useState({
        categorias : []
    })

    useEffect(() => {
        fetch(`${Config.apiURL}api/familia`)
            .then(response => response.json())
            .then(jsonData => {
                if(jsonData.length <= 4){
                    setState({
                        categorias : [jsonData]
                    })
                    return;
                }
                let categories = []
                let from = 0;
                while(from < jsonData.length){
                    const to = from + 4 <= jsonData.length ? from + 4 : jsonData.length
                    categories.push(jsonData.slice(from, to))
                    from = to
                }
                setState({
                    categorias : categories
                })
            })
            .catch(err =>{
                console.log(err)
            })
    },[]);

*/
    return (
        <div className="btn-group categories-btn">
            <Dropdown>
                <Dropdown.Toggle variant="secondary" className="dropdown-toggle">
                    <i className="icon-menu text-lg"></i>&nbsp;Categorias
                </Dropdown.Toggle> 
                <Dropdown.Menu className="mega-dropdown">

                    {
                        categorias.map((subArray, index) => (
                            <div  key={index} className="row">
                                {
                                    subArray.map((categoria, index2) => (
                                        <div key={index2} className="col-sm-3">
                                            <Link onClick={ () => { document.dispatchEvent(new MouseEvent('click')) } }     
                                                className="d-block navi-link text-center mb-30"
                                                to={ `/productos/categoria/${categoria.familiaId}/${ strTofriendlyUrl(categoria.nombre) }` }>
                                                <img alt={ categoria.nombre } className="d-block"
                                                    src={ categoria.imageUrl }/>
                                                <span className="text-gray-dark">{ categoria.nombre }</span>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                    ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default CategoriesMenu
