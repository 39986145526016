import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Config from '../../../Config'
import { strTofriendlyUrl } from '../../../utils'

const FooterCategories = ({ categorias }) => {
/*
	const [ categorias, setCategorias ] = useState([])

	useEffect(() =>{
		fetch(`${Config.apiURL}api/familia`)
            .then(response => response.json())
            .then(jsonData => {
				console.log(jsonData)
				if(jsonData.length <= 4){
					setCategorias([jsonData])
					return;
				}
				let categoriasPorColumna = jsonData.length / 4.0;
				let requiereExtra = parseInt(categoriasPorColumna) - categoriasPorColumna > 0;
				categoriasPorColumna = parseInt(categoriasPorColumna);
                const categories = []
				let from = 0;
                while(from < jsonData.length){
					let to = from + categoriasPorColumna <= jsonData.length ? from + categoriasPorColumna : jsonData.length
					if(requiereExtra && to + 1 < jsonData.length)
						to++;
					requiereExtra = false
                    categories.push(jsonData.slice(from, to))
                    from = to
                }
                setCategorias(categories)
            })
            .catch(err =>{
                console.log(err)
            })
	},[])
*/

	const arreglo = [];
	for(let i in categorias){
		const row = categorias[i];
		for(let j in row)
			arreglo.push(row[j]);
	}
	console.log(arreglo)

	return (
		<section className="widget widget-links widget-light-skin">
			<h3 className="widget-title">Categorias</h3>
			<ul className="row">
				{
					arreglo.map((categoria, index)=> {
						return (
							<li  className="col-md-3" key={ index }>
								<Link to={ `/productos/categoria/${categoria.familiaId}/${ strTofriendlyUrl(categoria.nombre) }` }>
								{ categoria.nombre }
								</Link>
							</li>
						)
					})		
				}
			</ul>
		</section>
	);
}

export default FooterCategories