import { getDiscountsForArticle, chooseDiscount } from '../utils'

const reducer = (state, action) => {
    switch(action.type){
        case 'UPDATE_SEO_INFO':

            console.log("------------------> Updating GAID ")
            if(state.gaID){
                console.log("------------------> GAID Already exists ")
                return {
                    ...state,
                    configuracionSeo : action.payload.seo
                };
            }
            console.log("------------------> GAID Updated")
            
            return {
                ...state,
                gaID : action.payload.seo.googleAnalyticId,
                configuracionSeo : action.payload.seo
            }
        case 'TOGGLE_MOBILE_MENU':
            return {
                ...state,
                mobileMenuIsOpen: !state.mobileMenuIsOpen
            } 
        case 'CHANGE_MOBILE_MENU_IS_OPEN':
            return {
                ...state,
                mobileMenuIsOpen: action.payload
            } 
        case 'ADD_TO_CART':
            if(state.cart[action.payload.articuloId]){
                state.cart[action.payload.articuloId].cantidad = 
                    state.cart[action.payload.articuloId].cantidad + 
                    action.payload.cantidad
            }else{
                state.cart[action.payload.articuloId] = action.payload
            }

            const item = state.cart[action.payload.articuloId];

            const descuentosParaArticulo = getDiscountsForArticle(
                state.descuentos,
                item.articuloId,
                item.familiaId
            )

            const choosenDiscount = chooseDiscount(
                descuentosParaArticulo,
                item.cantidad,
                item.precio
            )
            item.porcentajeDescuento = choosenDiscount ? choosenDiscount.discount : 0;
            item.precioEnUso = item.precio * (1 - item.porcentajeDescuento);

            localStorage.setItem("cart", JSON.stringify(state.cart))     
            return {
                ...state,
                cart : state.cart,
                cartChangesCounter : state.cartChangesCounter + 1
            }
        case 'REMOVE_FROM_CART':
            delete state.cart[action.payload.articuloId]    
            localStorage.setItem("cart", JSON.stringify(state.cart))  
            return {
                ...state,
                cart : state.cart,
                cartChangesCounter : state.cartChangesCounter + 1
            }
        case 'CLEAR_CART':   
            localStorage.setItem("cart", "{}")  
            return {
                ...state,
                cart : { },
                cartChangesCounter : state.cartChangesCounter + 1
            }
        case 'UPDATE_CART':   
            if(state.cart[action.payload.articuloId]){

                const item = state.cart[action.payload.articuloId];
                item.cantidad = action.payload.cantidad

                const descuentosParaArticulo = getDiscountsForArticle(
                    state.descuentos,
                    item.articuloId,
                    item.familiaId
                )

                const choosenDiscount = chooseDiscount(
                    descuentosParaArticulo,
                    item.cantidad,
                    item.precio
                )
                item.porcentajeDescuento = choosenDiscount ? choosenDiscount.discount : 0;
                item.precioEnUso = item.precio * (1 - item.porcentajeDescuento);
            }
            localStorage.setItem("cart", JSON.stringify(state.cart))     
            return {
                ...state,
                cart : state.cart,
                cartChangesCounter : state.cartChangesCounter + 1
            }
        
        case 'UPDATE_DISCOUNTS' :
            const descuentos = action.payload
            for(let i in state.cart){
                const item = state.cart[i];
                const descuentosParaArticulo = getDiscountsForArticle(
                    descuentos,
                    item.articuloId,
                    item.familiaId
                )
                const choosenDiscount = chooseDiscount(
                    descuentosParaArticulo,
                    item.cantidad,
                    item.precio
                )
                item.porcentajeDescuento  = choosenDiscount ? choosenDiscount.discount : 0;
                item.precioEnUso = item.precio * (1 - item.porcentajeDescuento);
            }
            return {
                ...state,
                cart : state.cart,
                descuentos: descuentos
            }

        case 'SET_USER' : 
            if(!action.payload.currentUser){
                localStorage.removeItem("token");
                localStorage.removeItem("client");
                return {
                    ...state,
                    currentClient : null,
                    currentUser : action.payload.currentUser
                }
            }
            return {
                ...state,
                currentUser : action.payload.currentUser
            }

        case 'SET_CLIENT' : 
            if(action.payload.currentClient)
                localStorage.setItem("client", JSON.stringify(action.payload.currentClient));
            else
                localStorage.removeItem("client");
            return {
                ...state,
                currentClient : action.payload.currentClient
            }
    }

    return state;
}

export default reducer;