import React from 'react'

const ResponsibleData = (props) => {
    return (
        <p>
            <strong>Responsable: </strong> { props.nombreResponsable }<br />
            <strong>Teléfono: </strong> <a href={ `tel:${ props.telefonoResponsable }` }>{ props.telefonoResponsable }</a>
        </p>
    )
}
export default ResponsibleData