import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const AccountMenu = ({ currentPage, currentClient }) => {
    return (
        <div className="col-lg-3">
            <aside className="user-info-wrapper">
                <div className="user-cover" >
                </div>
                <div className="user-info">
                <div className="user-data">
                    <h4 className="h5">
                        { currentClient.fullName }
                    </h4>
                </div>
                </div>
            </aside>
            <nav className="list-group">
                <Link className={ `list-group-item${ currentPage == "profile" ? " active" : " " }` } to="/mi-cuenta/mis-datos">
                    <i className="icon-user"></i>
                    Mis Datos
                </Link>
                <Link className={ `list-group-item${ currentPage == "orders" ? " active" : " " }`} to="/mi-cuenta/pedidos">
                    <i className="icon-shopping-bag"></i>
                    Pedidos
                </Link>
            </nav>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        firebase : state.firebase,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);