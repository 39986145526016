import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import { formatNumber, strTofriendlyUrl, chooseDiscount } from '../../utils'

const ProductItem = (props) => {
    
    const [stateObject, setStateObject] = useState({
        discountModalIsShown : false
    })

    const addToStateCart = (e) =>{
        e.preventDefault()
        props.onAddToCart({
            articuloId : props.articuloId,
            imagenUrl: props.imagenUrl,
            precio : props.precio,
            cantidad: 1,
            familiaId: props.familiaId,
            nombreFamilia : props.nombreFamilia, 
            nombreArticulo : props.nombreArticulo,
            cantidadDisponible : props.cantidadDisponible
        })


    }

    

    const handleDiscountModalClose = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault()
        setStateObject({
            ...stateObject,
            discountModalIsShown : false
        })
    }
    
    const handleShowDiscounts = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault()
        setStateObject({
            ...stateObject,
            discountModalIsShown : true
        })
    }




    const selectedDiscount = chooseDiscount(props.descuentos, 
        1, props.precio);
    console.log("Discount for " + props.nombreArticulo)
    console.log(selectedDiscount)
    const porcentajeDescuento = selectedDiscount ? selectedDiscount.discount : 0;

    const nuevoPrecio = props.precio * (1 - porcentajeDescuento);

    let oldPrice = "";
    let currentPrice = `Bs. ${ formatNumber(props.precio) }`
    if(nuevoPrecio < props.precio){
        oldPrice = <del className="text-muted" style={ { display : "block" } }>{ currentPrice }</del>
        currentPrice = ` Bs. ${ formatNumber(nuevoPrecio) }`
        
    }
    
    let addToCartButton = "";
    let badge = ""
    if(props.cantidadDisponible > 0){
        addToCartButton = 
            <a href="#" className="product-button" onClick={ addToStateCart }>
                <i className="icon-shopping-cart"></i> <span>Agregar al carrito</span>
            </a>
        if(props.descuentos && props.descuentos.length > 0)
            badge = <>
                    <div className="mb-3">
                        <button className="product-badge btn btn-sm btn-danger" type="button"
                            onClick={ handleShowDiscounts }>
                            <i className="icon-dollar-sign"></i>
                            &nbsp;&nbsp;
                            Ver Ofertas
                        </button>
                    </div>

                    <Modal show={ stateObject.discountModalIsShown  } onHide={ handleDiscountModalClose }>
                        <Modal.Header closeButton>
                            <Modal.Title>Hemos encontrado estas ofertas para este producto</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Oferta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.descuentos.map((item) => {
                                            return (
                                                <tr key={ item.descuentoId  }>
                                                    <td>
                                                        Entre { item.cantidadMin }
                                                        { item.cantidadMax < 0 ? ' o más' : ` y ${ item.cantidadMax }` }
                                                    </td>
                                                    <td>
                                                        {
                                                            item.descuentoXPorcentaje ? 
                                                            `${ formatNumber( item.porcentajeDescuento) }% de descuento` : 
                                                            `${ formatNumber( item.precioDescuento) }Bs de descuento`

                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </Modal.Body>
                    </Modal>
                    </>
    }else{
        badge = <div className="product-badge bg-secondary border-default text-body">Agotado</div>
    }
    const relativeUrl = `/producto/${props.articuloId}/${strTofriendlyUrl(props.nombreArticulo)}`
    const categoryUrl = `/productos/categoria/${props.familiaId}/${strTofriendlyUrl(props.nombreFamilia)}`
    return (
        <div className="product-card mb-30">
            { badge }
            <Link className="product-thumb" to={ relativeUrl }>
                <img src={props.imagenUrl} alt="Product" />
            </Link>
            <div className="product-card-body">
                <div className="product-category">
                    <Link to={ categoryUrl }>
                        { props.nombreFamilia }
                    </Link>
                </div>
                <h3 className="product-title">
                    <Link  to={ relativeUrl }>
                        {props.nombreArticulo}
                    </Link>
                </h3>
                <h4 className="product-price">
                    { oldPrice }{ currentPrice }
                </h4>


            </div>
            <div className="product-button-group">
                { addToCartButton }
                {/*<a className="product-button" target="_blank"                    
                    href={ `https://api.whatsapp.com/send?phone=${ Config.whatsappForOrder }&text=Quisiera%20pedir%20este%20producto%20 ${ Config.currentURL }producto/${ props.articuloId }` }>
                    <i className="socicon-whatsapp"></i> <span>Pedir por Whatsapp</span>
                </a>*/}
                <Link className="product-button" to={ relativeUrl }>
                    <i className="icon-arrow-right"></i><span>Ver Detalle</span>
                </Link>
            </div>
        </div>
    )
}

export default ProductItem;