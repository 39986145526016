import React from 'react'
import { Link } from 'react-router-dom'

const Pagination = ({ onPageChanged, baseUrl, currentPage, totalRows, pageSize })=>{

    let lastRowOfPage = currentPage * pageSize
    const firstRowOfPage = lastRowOfPage - pageSize + 1
    if(lastRowOfPage > totalRows)
        lastRowOfPage = totalRows;
    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;
    let lastPage = totalRows / pageSize;
    lastPage = lastPage - parseInt(lastPage) > 0 ?
        parseInt(lastPage) + 1 :
        parseInt(lastPage);


    const showPreviousButton = previousPage >= 1;
    const showNextButton = nextPage <= lastPage;

    return (
        <nav className="pagination">
            <div className="column pagination-info">
                { `Mostrando ${ firstRowOfPage } - ${ lastRowOfPage  } de ${ totalRows } items` }
            </div>
            <div className="column text-right col-xs-12">                
                {
                    showPreviousButton &&
                        <Link className="btn btn-outline-secondary btn-sm"
                            to={ `${baseUrl}page=${previousPage}` }>
                            <i className="icon-chevron-left"></i>&nbsp;Anterior
                        </Link>
                }
                {
                    showNextButton &&
                        <Link className="btn btn-outline-secondary btn-sm"
                            to={ `${baseUrl}page=${nextPage}` }>
                            Siguiente&nbsp;<i className="icon-chevron-right"></i>
                        </Link>
                }
            </div>
        </nav>
    )
}

export default Pagination;