import React from 'react'
import { Link } from 'react-router-dom'
import { formatNumber } from '../../../utils'

function ReviewOrder({ makingOrder, cart, generalInformation, paymentType, shipping, onBackButton, onCompleteOrder }) {

    const cartItems = [] 
    for (const id in cart) {
        const articulo = cart[id];
        cartItems.push(articulo);
    }

    const handleBackButton = (e) => {
        e.preventDefault();
        onBackButton();
    }
    
    const handleCompleteOrder = (e) => {
        e.preventDefault();
        onCompleteOrder();
    }

    return (
        <>
            <h4>Revise su pedido</h4>
            <hr className="padding-bottom-1x"></hr>

            <div className="row">
                <div className="col-sm-6">
                    <h5>Datos del Cliente:</h5>
                    <ul className="list-unstyled">
                        <li><span className="text-muted">Nombre:&nbsp; </span>{ generalInformation.nombreCompleto } </li>
                        <li><span className="text-muted">Teléfono:&nbsp; </span>{ generalInformation.telefono}</li>
                    </ul>
                    <h5>Datos del Facturación:</h5>
                    <ul className="list-unstyled">
                        <li><span className="text-muted">Nombre:&nbsp; </span>{ generalInformation.razonSocial.trim() == "" ? "SIN NOMBRE" : generalInformation.razonSocial }</li>
                        <li><span className="text-muted">Teléfono:&nbsp; </span>{ generalInformation.nit.trim() == "" ? "0" : generalInformation.nit }</li>
                    </ul>
                </div>
                <div className="col-sm-6">
                    <h5>Datos de Envío:</h5>
                    <ul className="list-unstyled">
                        <li><span className="text-muted">Ciudad:&nbsp; </span>{ shipping.cityName }</li>
                        <li><span className="text-muted">Address:&nbsp; </span>{ shipping.address }</li>
                    </ul>
                    <h5>Método de Pago:</h5>
                    <ul className="list-unstyled">
                        <li>
                            { 
                            paymentType == "PagoQR" ? 
                                "Transferencia Simple QR" :
                                "Pago contra Entrega"
                            }
                        </li>
                    </ul>
                </div>
            </div>


            <div className="table-responsive shopping-cart">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nombre del Producto</th>
                            <th className="text-center">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        cartItems.map((articulo) => {
                            let oldPrice = "";
                            if(articulo.precioEnUso < articulo.precio)
                                oldPrice = <span className="text-muted"><em>Precio antes:</em> <del>Bs. { formatNumber(articulo.precio) } </del></span>
                            
                            return  (
                                <tr key={ articulo.articuloId }>
                                    <td>
                                        <div className="product-item">
                                            <Link className="product-thumb" to={ `/producto/${ articulo.articuloId }` }>
                                                <img src={ articulo.imagenUrl } alt={ articulo.nombreArticulo }/>
                                            </Link>
                                            <div className="product-info">
                                                <h4 className="product-title">
                                                    <Link to={ `/producto/${ articulo.articuloId }` }>
                                                    { articulo.nombreArticulo }
                                                    </Link>
                                                </h4>
                                                { oldPrice }
                                                <span><em>Precio:</em> { formatNumber(articulo.precioEnUso) }</span>
                                                <span><em>Cantidad:</em> { articulo.cantidad }</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center text-lg">Bs. { formatNumber(articulo.precioEnUso * articulo.cantidad) }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

        <div className="d-flex justify-content-between paddin-top-1x mt-4">
            <a className={ `btn btn-outline-secondary${ makingOrder ? " disabled" : "" }` }
                onClick={ handleBackButton }
                href="#">
                <i className="icon-arrow-left"></i>
                <span className="hidden-xs-down">&nbsp;Back</span>
            </a>
            <a className={ `btn btn-primary${ makingOrder ? " disabled" : "" }` } href="#" onClick={ handleCompleteOrder }>
                {
                    makingOrder ? "Completando Pedido, espere por favor..." : "Completar Pedido"
                }
            </a>
        </div>
        </>
    )
}

export default ReviewOrder
