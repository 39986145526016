import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { updateDiscounts } from '../../actions'

import HomeSlider from './components/HomeSlider'
import FeaturedProducts from './components/FeaturedProducts'
import Config from '../../Config'
import SeoCompoment from '../../components/seo/SeoComponent'
 
const Home = (props) => {

    const [ destacados, setDestacados ] = useState([]);
    const [ seoConfig, setSeoConfig ] = useState(props.configuracionSeo);

    useEffect(() => {

        const url = `${Config.apiURL}api/articulo/destacados`;
        const headers = {}

        if(props.currentClient)
            headers.clienteid = props.currentClient.clienteId;
        
        fetch(url, {
                method: 'GET',
                headers: headers
            })
            .then((res) =>{
                return res.json();
            })
            .then((data) => {
                
                if(data){
                    setDestacados(data.articulos);
                    //setSeoConfig(data.configuracionSeo);
                    props.updateDiscounts(data.descuentos);
                }
                    
            })
            .catch((err =>{
                console.log(err)
                setDestacados([])
            }));
    },[props.currentClient])

    
    return (
        <>
            {/*   <Helmet>
                <title>Tienda Virtual</title>
                <meta
                    name="description"
                    content="Tienda Virtual powerded by Asistexa"
                    />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Tienda Virtual" />
                <meta property="og:description" content="Tienda Virtual powerded by Asistexa" />
                <meta property="og:image" content={ `${ Config.currentURL }img/logo/logo.png` } />
                <meta property="og:url" content={ Config.currentURL }  />
            </Helmet>*/}
        <SeoCompoment title={ "Home" } description={ Config.siteDescription } 
            isForHome={ true }>
            
        </SeoCompoment>
        

        <section className={ `hero-slider ${ !Config.slideshowBackgroundEnabled ? "no-background" : "" }` }>
            { console.log(destacados) }
            <HomeSlider items={ destacados ? destacados.length > 3 ? destacados.slice(0, 3) : destacados : [] } />
        </section>
        
        <FeaturedProducts destacados={ destacados }
             />
        </>
    )
}

const mapStateToProps = state => {
    return {
        currentClient : state.currentClient,
        configuracionSeo : state.configuracionSeo
    }
}
const mapDispatchToProps = { 
    updateDiscounts
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);