import React from 'react'
import { Helmet } from "react-helmet-async";
import { connect } from 'react-redux'
import Config from '../../Config'
const SeoComponent = (props) => {

    const seoConfig = props.configuracionSeo
    const relativeUrl = !props.relativeUrl ? "" : props.relativeUrl
    const title = `${ props.title } | ${ seoConfig.nombreComercial }`
    const description = props.description ? props.description : seoConfig.descripcionComercial
    const url = `${Config.currentURL}${relativeUrl}`
    const img = props.image ? props.image : `${Config.currentURL}img/logo/logo.png`

    const getStructuredDataForHome = () =>{
        
        if(!seoConfig)
            return "";
        
        const logo = seoConfig.logoFileId > 0 ? `${ Config.asistexaURL }image_${seoConfig.logoFileId}_270_92.jpg` : img;
        const fotoLocal = seoConfig.fotoTiendaFileId > 0 ? `${ Config.asistexaURL }image_${seoConfig.fotoTiendaFileId}_525_525.jpg` : img;
        
        const json = {
            "@id" : `${Config.currentURL}`,
            "@context": "https://schema.org",
            "@type": "Store",
            "url": `${Config.currentURL}`,
            "name" : seoConfig.nombreComercial,
            "description" : seoConfig.descripcionComercial,
            "openingHours": "",
            "telephone" : seoConfig.whatsappAtencionCliente,
            "paymentAccepted" : "Cash, Credit Card, Debit Card, QR Transfer",
            "address": {
                "@type" : "PostalAddress",
                "streetAddress" : seoConfig.direccion,
                "addressLocality" : seoConfig.ciudad,
                "addressRegion" : seoConfig.ciudad,
                "addressCountry" : "BO"
            },                    
            "photo": fotoLocal,
            "logo": logo,
            "image": logo,
            "geo" : {
                "@type": "GeoCoordinates",
                "latitude" : seoConfig.latitud,
                "longitude" : seoConfig.longitud,
            }
        }
        return JSON.stringify(json);
    }

    const getStructuredDataForProduct = () => {
        const data = props.productData;
        const json = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": data.descripcion,
            "image": [
                data.imageUrl
            ],
            "description": data.descripcionCorta,
            "sku": data.codigo,
            "brand": {
                "@type": "Brand",
                "name": data.nombreMarca
            },
            /*"review": {
                "@type": "Review",
                "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "4",
                    "bestRating": "5"
            },
            "author": {
                "@type": "Person",
                "name": "Fred Benson"
            }
            },
            "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.4",
            "reviewCount": "89"
            },*/
            "offers": {
                "@type": "Offer",
                "url": url,
                "priceCurrency": "BOB",
                "price": data.precio,
                "itemCondition": "https://schema.org/NewCondition",
                "availability":  "https://schema.org/InStock"
            }
        }
        return JSON.stringify(json);

    }
   
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ title }</title>
                <meta property="og:title'" content={ title } />
                <meta property="twitter:title'" content={ title } />

                <meta name="description" content={ description } />
                <meta property="og:description" content={ description } />
                <meta property="twitter:description" content={ description } />
                
                <meta property="og:url" content={ url } />
                <link rel="canonical" href={ url } />

                <meta property="og:image" content={ img } />

                {
                    props.isForHome && 
                    <script type="application/ld+json">
                        { getStructuredDataForHome() }
                    </script>
                }
                {
                    props.productData &&
                    <script type="application/ld+json">
                        { getStructuredDataForProduct() }
                    </script>
                }
            </Helmet>
           
            
        </>
    )
}

const mapStateToProps = state => {
    return {
        configuracionSeo : state.configuracionSeo
    }
}
const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(SeoComponent);