import React, { useState, useEffect } from 'react'
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import Config from '../../../Config'

const containerStyle = {
    width: '100%',
    height: '400px'
  };

const Tracking = (props) => {

    const [destiny, setDestiny] = useState(props.destiny);
    const [position, setPosition ] = useState(null);
    
    const [markerPosition, setMarkerPosition] = useState(null)
    const [markerDestiny, setMarkerDestiny] = useState(null)

    const lat = props.position ? props.position.lat : 0;
    const lng = props.position ? props.position.lng : 0;

    useEffect(() => {
        setPosition({
            lat,
            lng
        })
    }, [lat, lng])


    const onLoadMarkerPosition = React.useCallback(function callback(marker) {
        setMarkerPosition(marker)
        
    }, [])

    const onUnmountMarkerPosition = React.useCallback(function callback(marker) {
        setMarkerPosition(null)
      }, [])


    const onLoadMarkerDestiny = React.useCallback(function callback(marker) {
        setMarkerDestiny(marker)
        
    }, [])

    const onUnmountMarkerDestiny = React.useCallback(function callback(marker) {
        setMarkerDestiny(null)
    }, [])

    return (
        <LoadScript
            googleMapsApiKey={ Config.GoogleMapApiKey }>
            <GoogleMap
                mapContainerStyle={ containerStyle }
                center={ destiny }
                zoom={14}
                >

                {
                    position && 
                    <Marker onLoad={ onLoadMarkerPosition }
                        onUnmount={ onUnmountMarkerPosition }
                        label="Entregador"
                        position={ position } />
                }
                <Marker onLoad={ onLoadMarkerDestiny }
                        onUnmount={ onUnmountMarkerDestiny }
                        label="Destino"
                        position={ destiny } />
                
            </GoogleMap>
        </LoadScript>
    )
}
export default Tracking;