import React from 'react'
import { Link } from 'react-router-dom'
import SeoCompoment from '../../components/seo/SeoComponent'

const PedidoFinalizado = () => {
    return (
        <>
            <SeoCompoment title={ "Pedido Finalizado" }
                relativeUrl={ "pedido-finalizado" }
                description={ "Su pedido ha sido realizado correctamente" } />

            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Pedido Finalizado</h1>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="alert alert-success text-center margin-bottom-1x">
                            <i className="icon-check-circle"></i>&nbsp;&nbsp;
                            Su pedido ha sido registrado correctamente. En cualquier momento se comunicarán con usted para coordinar la entrega.
                        </div>
                        <Link to="/" className="btn btn-primary">Ir a la página de Inicio</Link>&nbsp;&nbsp;
                        <Link to="/productos" className="btn btn-secondary">Continuar comprando</Link><br/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PedidoFinalizado
