import React from 'react'
import { Link } from 'react-router-dom'
import { strTofriendlyUrl } from '../../../utils' 

const MiniCartItem = (
    { 
        onRemoveItem,
        nombreArticulo,
        precioForDislay,
        articuloId,
        cantidad,
        imagenUrl,
        friendlyNameUrl
    }) => {

    const removeItem = () =>{
        onRemoveItem({
            articuloId
        });
    }
    return (
        
<       div className="entry">
            <div className="entry-thumb">
                <Link to={ `/producto/${ articuloId }/${ friendlyNameUrl }` }>
                    <img src={ imagenUrl } alt={ nombreArticulo }/>
                </Link>
            </div>
            <div className="entry-content">
                <h4 className="entry-title">
                    <Link to={ `/producto/${ articuloId }/${ friendlyNameUrl }` }>
                        { nombreArticulo }
                    </Link>
                </h4>
                <span className="entry-meta">{ cantidad } x Bs. { precioForDislay }</span>
            </div>
            <div className="entry-delete" onClick={ removeItem }>
                <i className="icon-x"></i>
            </div>
        </div>
    )
}
export default MiniCartItem;