import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SeoCompoment from '../../components/seo/SeoComponent'
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2'
import es from 'react-phone-input-2/lang/es.json'

import Config from '../../Config'
import { setCurrentClient } from '../../actions'

import 'react-phone-input-2/lib/plain.css'

const Login = ({ firebase, history, setCurrentClient, currentClient }) => {

    const [ phoneNumber, setPhoneNumber ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ phoneNumberValidation, setPhoneNumberValidation ] = useState({
        wasValidated : false,
        validationMessage : ""
    });

    const [ requireVerificationCode, setRequireVerificationCode ] = useState(false);
    const [ loadingVerification, setLoadingVerification ] = useState(false);
    const [ verificationCode, setVerificationCode ] = useState("");
    const [ verificationCodeValidation, setVerificationCodeValidation ] = useState({
        wasValidated : false,
        validationMessage : ""
    });

    useEffect(() => {
        setupRecaptcha();
    },[]);

    const performLogin =(e) =>{
        e.preventDefault();
        if(window.recaptchaVerifier)
            sendLogin();
        else
            setupRecaptcha();
    }

    const sendLogin = () =>{
        var appVerifier = window.recaptchaVerifier;

        let number = phoneNumber;


        if(!number || number.trim() == "" || number.length < 5){
            setPhoneNumberValidation({
                wasValidated : true,
                validationMessage : "Debe ingresar su número de celular"
            });
            return;
        }
        setPhoneNumberValidation({
            wasValidated : true,
            validationMessage : ""
        });

        if(!number.startsWith("+"))
            number = "+" + number;

            
        setLoading(true);
        firebase.auth().signInWithPhoneNumber(number, appVerifier)
            .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                setLoading(false);
                setRequireVerificationCode(true);
               /* let code = verificationCode; //window.prompt("Ingrese el codigo recibido");

                window.confirmationResult.confirm(code)
                    .then((result) =>{
                        console.log( result.user)
                        //9let user =
                    })
                    .catch((err) =>{
                        console.log(err)
                    });*/
            }).catch(function (error) {
            });
    }

    const setupRecaptcha = ()=>{
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-verifier', {
            'size': 'invisible',
            'callback': function(response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              console.log(response)
            }
        });
    }

    const handleClose = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault()
        setLoadingVerification(false);
        setRequireVerificationCode(false);
        setVerificationCode("");
        setVerificationCodeValidation({
            wasValidated : false,
            validationMessage : ""
        })
    }

    const handleSendVerificationCode = (e) =>{
        if(e && e.preventDefault)
            e.preventDefault();

        if(verificationCode.trim() == ""){
            setVerificationCodeValidation({
                wasValidated : true,
                validationMessage : "Debe ingresar el código de verificación"
            });
            return;
        }
        setVerificationCodeValidation({
            wasValidated : true,
            validationMessage : ""
        });
        setLoadingVerification(true)
        window.confirmationResult.confirm(verificationCode)
            .then((result) =>{
                loginClient();
            })
            .catch((error) =>{
                setLoadingVerification(false);
                if(error.code && error.code == "auth/invalid-verification-code"){
                    setVerificationCodeValidation({
                        wasValidated : true,
                        validationMessage : "El código de verificación ingresado es invalido. Por favor verifique que el código ingresado es el correcto o intente nuevamente."
                    });
                }else{
                    setVerificationCodeValidation({
                        wasValidated : true,
                        validationMessage : "No se pudo verificar el código ingresado, por favor intente nuevamente."
                    });
                }
            });
    }

    const loginClient = () => {

        let number = phoneNumber;
        if(!number.startsWith("+"))
            number = "+" + number;

        const data = {
            "phoneNumber" : number
        }

        const url = Config.apiURL + 'api/cliente/login'
        fetch(url, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(json => {
            setCurrentClient({
                currentClient : json.cliente
            });
            localStorage.setItem("token", json.token);
            setRequireVerificationCode(false);
            if(json.isNew || json.cliente.fullName == "." ){                
                history.push("/welcome?isNew=true")
            }
            else{
                history.push("/welcome")
            }
            
        }).catch(err => {
            console.log(err);
            


        });
        /*
       
        
        */
    }


    return (
        <>
            <SeoCompoment title="Login"
                
             />
            <Modal show={ requireVerificationCode  } onHide={ handleClose }>
                <Modal.Header>
                    <h4 className="modal-title">Ingrese su código de verificación</h4>
                </Modal.Header>
                <Modal.Body>
                    
                    <p>
                        Se ha enviado un código de verificación via SMS al 
                        número telefónico ingresado.
                    </p>

                    <form className={ verificationCodeValidation.wasValidated ? "row was-validated" : "row" }>
                        <div className="offset-md-2 col-md-8">
                        <div className="form-group">
                            <label>Código de Verificación</label>
                            <input className={ `form-control${ verificationCodeValidation.validationMessage == "" ? "" : " is-invalid" }` }
                                type="text"
                                onChange={ e => setVerificationCode(e.target.value) }
                                placeholder="Ingese su código de verificación"/>
                            <div className="invalid-feedback">{ verificationCodeValidation.validationMessage  }</div>
                        </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <a href="#" className="btn btn-secondary" onClick={ handleClose }>
                        Cancelar
                    </a>
                    <button className="btn btn-primary"
                        disabled={ loadingVerification }
                        onClick={ handleSendVerificationCode }>
                        { loadingVerification ? "Verificando... " : "Verificar " }
                        <i className="icon-check"></i>
                    </button>
                </Modal.Footer>
            </Modal>
            
            <div className="page-title">
                <div className="container">
                    <div className="column">
                    <h1>Login</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Inicio</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Mi Cuenta</li>
                        <li className="separator">&nbsp;</li>
                        <li>Login</li>
                    </ul>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <div className="offset-md-3 col-md-6 ">
                        <form className={ phoneNumberValidation.wasValidated ? "card was-validated" : "card" }
                            onSubmit={ performLogin }>
                            <div className="card-body">
                                <h4 className="margin-bottom-1x">Ingrese a traves de su número de celular</h4>
                                
                                <div id="recaptcha-verifier"></div>
                                {/*<div className="form-group input-group">*/}

                                <PhoneInput 
                                    enableSearch={ true } localization={es} searchPlaceholder="Buscar..."
                                    searchNotFound="Busqueda no encontrada"
                                    containerClass={ `${ phoneNumberValidation.validationMessage == "" ? "" : " is-invalid" }` }
                                    inputClass={ `form-control${ phoneNumberValidation.validationMessage == "" ? "" : " is-invalid" }` }
                                    country={'bo'} placeholder="Ingrese su número de celular"
                                    onChange={phone => setPhoneNumber(phone)}
                                    countryCodeEditable={ false }
                                    />
                                <div className="invalid-feedback">{ phoneNumberValidation.validationMessage  }</div>
                                  
                                <div className="text-center text-sm-right">
                                    <input className="btn btn-primary margin-bottom-none"
                                        disabled={ loading }
                                        type="submit" value={ loading ? "Verificando..." : "Ingresar" } />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        firebase : state.firebase,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = {
    setCurrentClient
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));