import React, { Component } from 'react'
import FooterCategories from './components/FooterCategories'

const Footer = ({ categorias }) => {

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <FooterCategories  categorias={ categorias } />
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-lg-12 widget text-center widget-links widget-light-skin">
                        Powered by <a rel="noopener noreferrer" href="https://artexacta.com" target="_blank">Artexacta S.R.L.</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
