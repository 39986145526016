import React from 'react';
import { Link, withRouter } from 'react-router-dom'

const MainMenu = (props) => {

    return (
        <>
            {/*<div className="btn-group categories-btn">
                <button className="btn btn-secondary dropdown-toggle" data-toggle="dropdown"><i className="icon-menu text-lg"></i>&nbsp;Categories</button>
                <div className="dropdown-menu mega-dropdown">
                    <div className="row">
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/01.jpg" /><span className="text-gray-dark">Computers &amp; Accessories</span></a></div>
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/02.jpg" /><span className="text-gray-dark">Smartphones &amp; Tablets</span></a></div>
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/03.jpg" /><span className="text-gray-dark">TV, Video &amp; Audio</span></a></div>
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/04.jpg" /><span className="text-gray-dark">Cameras, Photo &amp; Video</span></a></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/05.jpg" /><span className="text-gray-dark">Headphones</span></a></div>
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/06.jpg" /><span className="text-gray-dark">Wearable Electronics</span></a></div>
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/07.jpg" /><span className="text-gray-dark">Printers &amp; Ink</span></a></div>
                        <div className="col-sm-3"><a className="d-block navi-link text-center mb-30" href="shop-grid-ls.html"><img className="d-block" src="img/shop/header-categories/08.jpg" /><span className="text-gray-dark">Video Games</span></a></div>
                    </div>
                </div>
            </div>*/}
            <nav className="site-menu">
                <ul>
                    <li className={ props.location.pathname === "/" ? "active" : "" }  >
                        <Link to="/">Inicio</Link>
                    </li>
                    <li className={ 
                            props.location.pathname.startsWith("/producto") ? "active" : "" 
                        } >
                        <Link to="/productos">
                            Productos
                        </Link>
                    </li>
                    {/*
                    <li className={ 
                            props.location.pathname.startsWith("/nosotros") ? "active" : "" 
                        } >
                        <Link to="/nosotros">Nosotros</Link>
                    </li>
                    <li className="has-submenu"><a href="#">Mi Cuenta</a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/login">Login / Register</Link>
                            </li>
                        </ul>
                    </li>*/}
                </ul>
            </nav>
            {/*<div className="toolbar">
                <div className="toolbar-inner">
                    <div className="toolbar-item"><a href="product-comparison.html">
                        <div><span className="compare-icon"><i className="icon-repeat"></i><span className="count-label">3</span></span><span className="text-label">Compare</span></div></a></div>
                    <div className="toolbar-item"><a href="cart.html">
                        <div><span className="cart-icon"><i className="icon-shopping-cart"></i><span className="count-label">3   </span></span><span className="text-label">Cart</span></div></a>
                        <div className="toolbar-dropdown cart-dropdown widget-cart">
                            <div className="entry">
                                <div className="entry-thumb"><a href="shop-single.html"><img src="img/shop/widget/04.jpg" alt="Product" /></a></div>
                                <div className="entry-content">
                                    <h4 className="entry-title"><a href="shop-single.html">Canon EOS M50 Mirrorless Camera</a></h4><span className="entry-meta">1 x $910.00</span>
                                </div>
                                <div className="entry-delete"><i className="icon-x"></i></div>
                            </div>
                            <div className="entry">
                                <div className="entry-thumb"><a href="shop-single.html"><img src="img/shop/widget/05.jpg" alt="Product" /></a></div>
                                <div className="entry-content">
                                    <h4 className="entry-title"><a href="shop-single.html">Apple iPhone X 256 GB Space Gray</a></h4><span className="entry-meta">1 x $1,450.00</span>
                                </div>
                                <div className="entry-delete"><i className="icon-x"></i></div>
                            </div>
                            <div className="entry">
                                <div className="entry-thumb"><a href="shop-single.html"><img src="img/shop/widget/06.jpg" alt="Product" /></a></div>
                                <div className="entry-content">
                                    <h4 className="entry-title"><a href="shop-single.html">HP LaserJet Pro Laser Printer</a></h4><span className="entry-meta">1 x $188.50</span>
                                </div>
                                <div className="entry-delete"><i className="icon-x"></i></div>
                            </div>
                            <div className="text-right">
                                <p className="text-gray-dark py-2 mb-0"><span className="text-muted">Subtotal:</span> &nbsp;$2,548.50</p>
                            </div>
                            <div className="d-flex">
                                <div className="pr-2 w-50"><a className="btn btn-secondary btn-sm btn-block mb-0" href="cart.html">Expand Cart</a></div>
                                <div className="pl-2 w-50"><a className="btn btn-primary btn-sm btn-block mb-0" href="checkout.html">Checkout</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </>
    )

}

export default withRouter(MainMenu)