import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { emailIsValid,hasOnlyNumbers } from '../../utils'
import AccountMenu from './components/AccountMenu'
import { setCurrentClient } from '../../actions'
import Config from '../../Config'
import SeoComponent from '../../components/seo/SeoComponent'

const Profile = ({ currentClient, setCurrentClient }) => {

    const [ showMessage, setShowMessage ] = useState(false)
    const [ currentMessage, setCurrentMessage ] = useState({
        message : "",
        type : "none",
        messageCounter : 0
    })
    
    const [ updatingClient, setUpdatingClient ] = useState(false);
    const [ clientData, setClientData] = useState({
        fullName : currentClient.fullName,
        email: currentClient.email,
        nit: currentClient.nit,
        clientId : 0
    })


    const [ validation, setValidation ] = useState({
        fullNameValidationMessage : "",
        emailValidationMessage: "",
        nitValidationMessage: "",
        wasValidated : false
    })

    useEffect(() => {

        if(clientData.clientId == 0){
            const url =  `${ Config.apiURL }api/cliente/${ currentClient.clienteId }`

            
            const headers = {}
        
            const token = localStorage.getItem("token");
            if(token)
                headers.Authorization = `Bearer ${ token }`

            fetch(url, {
                method: 'GET',
                headers: headers
            })
                .then(response => { return response.json() })
                .then(json => {
                    if(json && json.success){

                        setClientData({
                            ...clientData,
                            clientId: json.cliente.clienteId
                        })
                        setCurrentClient({
                            currentClient : {
                                ...currentClient,
                                fullName : json.cliente.fullName,
                                clienteId : json.cliente.clienteId,
                                nit: json.cliente.nit,
                                email: json.cliente.email,
                                emailVerified: json.cliente.emailVerified
                            }
                        });
                        
                    }
                })
                .catch(err => {
                    setClientData({
                        ...clientData,
                        clientId: currentClient.clienteId
                    })
                    setCurrentMessage({
                        message : "Ocurrio un error obtener los datos",
                        type: "error",
                        messageCounter : currentMessage.messageCounter  + 1
                    })
                })

        }

        
        let timer = null;
        if(currentMessage.message != ""){
            setShowMessage(true);
            timer = setTimeout(() => {
                setShowMessage(false);
                setCurrentMessage({
                    ...currentMessage,
                    message: ''
                })
            }, 5000);
        }
        return () => {
            if(timer) 
                clearTimeout(timer);
        }
    }, [currentMessage.messageCounter, clientData.clientId])

    const updateClient = (e) => {
        e.preventDefault();


        const newValidation = {
            fullNameValidationMessage : "",
            emailValidationMessage: "",
            nitValidationMessage: ""
        }

        let valid = true;
        if(clientData.fullName.trim() == ""){
            newValidation.fullNameValidationMessage = "Debe ingresar su nombre completo";
            valid = false;
        }

        if(clientData.email.trim() != "" && !emailIsValid(clientData.email)){
            newValidation.emailValidationMessage = "El correo electrónico ingresado no tiene el formato correcto";
            valid = false;
        }

        if(clientData.nit.trim() != "" && !hasOnlyNumbers(clientData.nit)){
            newValidation.nitValidationMessage = "El NIT/CI debe contener solo números";
            valid = false;
        }

        newValidation.wasValidated = true;

        setValidation(newValidation);

        if(!valid)
            return;

        
        setUpdatingClient(true);
        const data = {
            RazonSocial : clientData.fullName,
            ServerClienteId : currentClient.clienteId,
            SitioWeb : clientData.email,
            Nit : clientData.nit
        };

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
    
        const url = Config.apiURL + 'api/cliente/update'
        const token = localStorage.getItem("token");
        if(token)
            headers.Authorization = `Bearer ${ token }`
        fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {

            setCurrentMessage({
                message : "Los datos han sido correctamente actualizados",
                type: "success",
                messageCounter : currentMessage.messageCounter  + 1
            })
            setCurrentClient({
                currentClient : {
                    ...currentClient,
                    fullName : json.cliente.fullName,
                    clienteId : json.cliente.clienteId,
                    nit: json.cliente.nit,
                    email: json.cliente.email,
                    emailVerified: json.cliente.emailVerified
                }
            });
            setValidation({
                ...validation,
                wasValidated : false
            })
            setUpdatingClient(false);
            
        }).catch(err => {
            console.log(err)
            setUpdatingClient(false);

            setCurrentMessage({
                message : "Ocurrio un error al actualizar los datos",
                type: "error",
                messageCounter : currentMessage.messageCounter  + 1
            })
            
        });
    }

    const resendEmailVerification = (e) => {
        e.preventDefault();

        const data = {
            ClienteId : currentClient.clienteId
        };

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
    
        const url = Config.apiURL + 'api/cliente/resend-email-validation'
        const token = localStorage.getItem("token");
        if(token)
            headers.Authorization = `Bearer ${ token }`
        fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(json => {
            
            if(json.success)
                localStorage.setItem("token", json.token)
            
            setCurrentMessage({
                message : "Se ha enviado un mensaje de a su correo electrónico para verificar su dirección. Por favor revise su correo electrónico.",
                type: "success",
                messageCounter : currentMessage.messageCounter  + 1
            })
            
            
        }).catch(err => {
            console.log(err)

            setCurrentMessage({
                message : "Ocurrio un error al re-enviar la verificación del correo electrónico",
                type: "error",
                messageCounter : currentMessage.messageCounter  + 1
            })
            
        });
    }

    return (
        <>
        
            <SeoComponent title="Mis Datos" />
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Mis Datos</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>
                            Mi Cuenta
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Mis Datos</li>
                    </ul>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <AccountMenu currentPage="profile" />
                    <div className="col-lg-9">
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>

                        {
                            showMessage && currentMessage.message.trim() != "" ?
                                currentMessage.type == "success" ? 
                                <div className="alert alert-success text-center margin-bottom-1x">
                                    
                                    <i className="icon-check-circle"></i>&nbsp;&nbsp;
                                    { currentMessage.message }
                                </div>
                                : 
                                currentMessage.type == "error" ? 
                                <div className="alert alert-danger text-center margin-bottom-1x">
                                    
                                    <i className="icon-check-circle"></i>&nbsp;&nbsp;
                                    { currentMessage.message }
                                </div>
                                : 
                                ""
                            : ""
                        }

                        <form className={ validation.wasValidated ? "row was-validated" : "row" } onSubmit={ updateClient }>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="account-fn">Nombre Completo</label>
                                    <input className={ `form-control${ validation.fullNameValidationMessage == "" ? "" : " is-invalid" }` }
                                        type="text" value={ clientData.fullName }
                                        onChange={ (e) => setClientData({ ...clientData, fullName: e.target.value }) } />
                                    <div className="invalid-feedback">{ validation.fullNameValidationMessage  }</div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="account-fn">Número Celular</label>
                                    <input className="form-control" type="text" value={ currentClient.telefono } disabled />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="account-fn">Correo Electrónico</label>
                                    <input className={ `form-control${ validation.emailValidationMessage == "" ? "" : " is-invalid" }` }
                                        type="email" value={ clientData.email }
                                        onChange={ (e) => setClientData({ ...clientData, email: e.target.value }) } />
                                    <div className="invalid-feedback">{ validation.emailValidationMessage  }</div>
                                </div>

                                {
                                    clientData.email != currentClient.email ?
                                        <div className="alert alert-warning">
                                            <i className="icon-alert-triangle"></i>
                                            &nbsp;&nbsp;
                                            Al modificar su correo electrónico, se le enviará un mensaje a la dirección 
                                            ingresada para validar dicha dirección
                                        </div>
                                    :
                                        ""
                                }

                                {
                                    !currentClient.emailVerified ?
                                        <div className="alert alert-warning">
                                            <i className="icon-alert-triangle"></i>
                                            &nbsp;&nbsp;
                                            El correo electrónico { currentClient.email } no ha sido verificado. 
                                            Revise su correo electrónico.
                                            <br/>
                                            <input type="button" value="Re-enviar verificación" 
                                                className="btn btn-secondary"
                                                onClick={ resendEmailVerification } />
                                        </div>
                                    : 
                                        ""
                                }
                                
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="account-fn">NIT o Cédula de Identidad</label>
                                    <input className={ `form-control${ validation.nitValidationMessage == "" ? "" : " is-invalid" }` }
                                        type="text" value={ clientData.nit } 
                                        onChange={ (e) => setClientData({ ...clientData, nit: e.target.value }) } />
                                    <div className="invalid-feedback">{ validation.nitValidationMessage  }</div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <input className="btn btn-primary margin-bottom-none"
                                    disabled={ updatingClient }
                                    type="submit"
                                    value={ updatingClient ? "Actualizando Datos..." : "Actualizar Datos" } />
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = state => {
    return {
        firebase : state.firebase,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = {
    setCurrentClient
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);