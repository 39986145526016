import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateDiscounts } from '../../actions'

import CategoriesMenu from './components/CategoriesMenu'
import BrandsMenu from './components/BrandsMenu'
import ProductList from './components/ProductList'
import Pagination from './components/Pagination'
import Config from '../../Config'
import SeoCompoment from '../../components/seo/SeoComponent'

const Products = (props) => {


    const queryStringParams = new URLSearchParams(props.location.search);
    const { categoriaId, subCategoriaId, marcaId } = props.match.params;
    const query = queryStringParams.has("q") ?
        `&query=${encodeURIComponent(queryStringParams.get("q"))}` : "";
    const currentPage = queryStringParams.has("page") ?
        Number(queryStringParams.get("page")) : 1;

    let queryString = queryStringParams.has("q") ?
        `?q=${queryStringParams.get("q")}&` : "?"

    const baseUrl = props.location.pathname + queryString

    const [state, setState] = useState({
        articulos: [],
        categorias: [],
        marcas: [],
        currentPage: currentPage,
        totalRows: 0,
        loading: true,
        title : "Productos"
    })

    /*const onPageChanged = (newPage) => {
        setState({
            ...state,
            currentPage: newPage
        })
    }*/

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setState({
            loading: true
        })

        console.log("fetching data")
        const fromRow = (currentPage - 1) * Config.pageSize;
        let url = `${Config.apiURL}api/articulo/search?pageSize=${Config.pageSize}${query}&fromRow=${fromRow}`
        if (categoriaId)
            url = `${url}&familia=${categoriaId}`
        
        if (subCategoriaId)
            url = `${url}&tipo=${subCategoriaId}`

        if (marcaId)
            url = `${url}&marca=${marcaId}`

        const headers = {}
        if(props.currentClient)
            headers.clienteid = props.currentClient.clienteId;
        
        fetch(url, {
                method: 'GET',
                headers: headers
            })
            .then(response => response.json())
            .then(jsonData => {
                let articulos = []
                let from = 0;
                const length = jsonData.articulos.length
                while (from < length) {
                    const to = from + 3 <= length ? from + 3 : length
                    articulos.push(jsonData.articulos.slice(from, to))
                    from = to
                }

                props.updateDiscounts(jsonData.descuentos);
                setState({
                    articulos: articulos,
                    categorias: jsonData.familias,
                    marcas: jsonData.marcas,
                    totalRows: jsonData.totalRows,
                    loading: false,
                    title: jsonData.tituloBusqueda
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [categoriaId, subCategoriaId, marcaId, query, currentPage, props.currentClient])

    if (state.loading) {

        return (
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Cargando...</h1>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>

            <SeoCompoment title={ state.title }
                relativeUrl={ props.location.pathname }
                description={ Config.siteDescription } />
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>{ state.title }</h1>
                    </div>
                    <div className="column">
                        <ul className="breadcrumbs">
                            <li>
                                <Link to="/">Inicio</Link>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Productos</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="container padding-bottom-3x mb-1">
                <div className="row">
                    <div className="col-lg-9 order-lg-2">
                        {/*<div className="shop-toolbar padding-bottom-1x mb-2">
                            <div className="column">
                                <div className="shop-sorting">
                                    <label htmlFor="sorting">Sort by:</label>
                                    <select className="form-control" id="sorting">
                                    <option>Popularity</option>
                                    <option>Low - High Price</option>
                                    <option>High - Low Price</option>
                                    <option>Average Rating</option>
                                    <option>A - Z Order</option>
                                    <option>Z - A Order</option>
                                    </select><span className="text-muted">Showing:&nbsp;</span><span>1 - 12 items</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="shop-view">
                                    <a className="grid-view active"
                                        href="shop-grid-ls.html">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </a>
                                    <a className="list-view" href="shop-list-ls.html">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>*/}

                        <ProductList articulos={state.articulos} />
                        { //currentPage, totalRows, pageSize, currentQueryString
                            state.totalRows > Config.pageSize &&
                            <Pagination currentPage={currentPage}
                                totalRows={state.totalRows}
                                baseUrl={baseUrl}
                                pageSize={Config.pageSize} />
                        }

                    </div>

                    <div className="col-lg-3 order-lg-1">
                        <aside className="sidebar sidebar-offcanvas position-left">
                            <CategoriesMenu categorias={state.categorias}
                                categoriaActual={ categoriaId }
                                subCategoriaActual={ subCategoriaId } />
                            <BrandsMenu marcas={ state.marcas }
                                marcaActual={ marcaId } />
                        </aside>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = { 
    updateDiscounts
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

