import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { emailIsValid,hasOnlyNumbers } from '../../utils'
import SeoComponent from '../../components/seo/SeoComponent'
import Config from '../../Config'
import { setCurrentClient } from '../../actions'

const Welcome = ({ firebase, location, history, setCurrentClient, currentClient }) => {

    const queryStringParams = new URLSearchParams(location.search);
    const isNew = queryStringParams.has("isNew") && queryStringParams.get("isNew") == "true";
    
    const [ notMe, setNotMe ] = useState(false);

    const [ clientName, setClientName ] = useState("");
    const [ nit, setNit ] = useState("");
    const [ email, setEmail ] = useState("");

    const [ clientNameValidation, setClientNameValidation ] = useState({
        wasValidated : false,
        validationMessage : ""
    });
    const [ emailValidation, setEmailValidation ] = useState({
        wasValidated : false,
        validationMessage : ""
    });
    const [ nitValidation, setNitValidation ] = useState({
        wasValidated : false,
        validationMessage : ""
    });

    const [ updatingClient, setUpdatingClient ] = useState(false);


    const updateClient = (e) => {
        e.preventDefault();

        let hasError = false;

        if(clientName.trim() == ""){
            setClientNameValidation({
                validationMessage : "Debe ingresar su nombre completo",
                wasValidated : true
            })
            hasError = true;
        }else{
            setClientNameValidation({
                validationMessage : "",
                wasValidated : true
            })
        }

        if(email.trim() != "" && !emailIsValid(email) ){
            setEmailValidation({
                validationMessage : "El correo electrónico no tiene el formato correcto",
                wasValidated : true
            })
            hasError = true;
        }else{
            setEmailValidation({
                validationMessage : "",
                wasValidated : true
            })
        }

        if(nit.trim() != "" && !hasOnlyNumbers(nit) ){
            setNitValidation({
                validationMessage : "El NIT/CI debe contener solo números",
                wasValidated : true
            })
            hasError = true;
        }else{
            setNitValidation({
                validationMessage : "",
                wasValidated : true
            })
        }

        if(hasError)
            return;

        const data = {
            RazonSocial : clientName,
            ServerClienteId : currentClient.clienteId,
            SitioWeb : email,
            Telefono : currentClient.telefono,
            Nit : nit
        };
        setUpdatingClient(true);
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }

        const url = notMe ? Config.apiURL + 'api/cliente/relogin' :  Config.apiURL + 'api/cliente/update'
        const token = localStorage.getItem("token");
        if(token && token != "")
            headers.Authorization = `Bearer ${ token }`
        fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(json => {
            
            setCurrentClient({
                currentClient : {
                    ...currentClient,
                    fullName : json.cliente.fullName,
                    clienteId : json.cliente.clienteId,
                    email: json.cliente.email,
                    nit: json.cliente.nit,
                    emailVerified : json.cliente.emailVerificado
                }
            });
            localStorage.setItem("token", json.token);
            setNotMe(false)
            history.push("/welcome");
            
        }).catch(err => {
            console.log(err)
            setClientNameValidation({
                validationMessage : "Ocurrió un error al registrar los datos",
                wasValidated : true
            })
            setUpdatingClient(false);

        });
    }

    const handleNotMe = (e) => {
        e.preventDefault();
        setNotMe(true);
    }

    if(isNew || currentClient.fullName == "." || notMe){
        return (
            <>
            <SeoComponent title="Finalizando el registro" />
            <div className="page-title">
                <div className="container">
                    <div className="column">
                    <h1>Finalizando el registro</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Inicio</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Mi Cuenta</li>
                        <li className="separator">&nbsp;</li>
                        <li>Registro</li>
                    </ul>
                    </div>
                </div>
            </div>
    
            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <div className="offset-md-3 col-md-6 ">
                        <form className={ clientNameValidation.wasValidated ? "card was-validated" : "card" }
                            onSubmit={ updateClient }>
                            <div className="card-body">
                                {
                                    notMe ?
                                    <>
                                    <h4 className="margin-bottom-1x">Continuar Registro.</h4>
                                    <h5>Por favor ingrese sus datos</h5>
                                    </>
                                    :
                                    <>
                                    <h4 className="margin-bottom-1x">Bienvenido.</h4>
                                    <h5>Para finalizar, por favor ingrese sus datos</h5>
                                    </>
                                }
                                <div className="form-group">
                                    <input type="text"
                                        placeholder="Ingrese su nombre completo"
                                        className={ `form-control${ clientNameValidation.validationMessage == "" ? "" : " is-invalid" }` }
                                        onChange={ e => setClientName(e.target.value) } />
                                    <div className="invalid-feedback">{ clientNameValidation.validationMessage  }</div>
                                </div>
                                <div className="form-group">
                                    <input type="number"
                                        placeholder="Ingrese su NIT o Cédula de Identidad"
                                        className={ `form-control${ nitValidation.validationMessage == "" ? "" : " is-invalid" }` }
                                        onChange={ e => setNit(e.target.value) } />
                                    <div className="invalid-feedback">{ nitValidation.validationMessage  }</div>
                                </div>
                                <div className="form-group">
                                    <input type="email"
                                        placeholder="Ingrese su correo electrónico" 
                                        className={ `form-control${ emailValidation.validationMessage == "" ? "" : " is-invalid" }` }
                                        onChange={ e => setEmail(e.target.value) } />
                                    <div className="invalid-feedback">{ emailValidation.validationMessage  }</div>
                                </div>
                                <div className="text-center text-sm-right">
                                    <input className="btn btn-primary margin-bottom-none"
                                        disabled={ updatingClient }
                                        type="submit"
                                        value={ updatingClient ? "Registrando..." : "Finalizar Registro" } />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    
            </>
        )
    }



    const relogin = () => {

    }

    return (
        <>
        <SeoComponent title="Bienvenido" />
        <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Bienvenido</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Inicio</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Mi Cuenta</li>
                    <li className="separator">&nbsp;</li>
                    <li>Bienvenido</li>
                </ul>
                </div>
            </div>
        </div>

        <div className="container padding-bottom-3x mb-2">
            <div className="row">
                <div className="offset-md-3 col-md-6 ">
                    <div className="card"
                        >
                        <div className="card-body">
                            <h4 className="margin-bottom-1x">Bienvenido
                                { ` ${ currentClient.fullName }`}
                            </h4>
                            <div>
                                <input type="button" className="btn btn-primary" 
                                    value="No soy yo..." onClick={ handleNotMe } />
                                <Link className="btn btn-secondary"
                                    to="/productos">
                                    Continuar comprando
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
    
}


const mapStateToProps = state => {
    return {
        firebase : state.firebase,
        currentClient : state.currentClient
    }
}
const mapDispatchToProps = {
    setCurrentClient
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Welcome));