import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import ResponsibleData from './components/ResponsibleData'
import Tracking from './components/Tracking'
import Config from '../../Config'

const Delivery = (props) => {

    const [ loading, setLoading ] = useState(true);
    const [ currentOrder, setCurrentOrder ] = useState(null);
    const [ timer , setTimer ] = useState(null)

    const [ currentAction, setCurrentAction ] = useState("getOrder");
    const [ counter, setCounter ] = useState(0);

    const { ventaId } = props.match.params;

    useEffect(() => {
        const theTimer = setTimeout(() => {
            getOrderStatus();
        }, 1000);
        setTimer(theTimer)
        return () => {
            if(timer) 
                clearTimeout(timer);
        }
    }, [])

    const getOrderStatus = () => {
        const url = `${ Config.apiURL }api/pedido/entrega/${ ventaId }`

        fetch(url)
            .then(response => response.json())
            .then(json => {
                let requestAgain = false;
                if(json && json.success){
                    setCurrentOrder(json.data)
                    setCounter(counter + 1)
                    requestAgain = json.data.estado != "ENTREGADO"
                }
                    
                setLoading(false)
                if(!requestAgain)
                    return;
                const theTimer = setTimeout(() => {
                    getOrderStatus();
                }, 1000);
                setTimer(theTimer)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }


    if(loading){
        return (
            <>
                <div className="page-title">
                    <div className="container">
                        <div className="column">
                            <h1>
                                Cargando...
                            </h1>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }

    if(currentOrder == null)
    {
        return (
            <>
                <div className="page-title">
                    <div className="container">
                        <div className="column">
                            <h1>
                                No se pudo cargar el estado de la entrega o probablemente el proceso de entrega no ha iniciado
                            </h1>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
    const destiny = {
        lat : currentOrder.latitud,
        lng : currentOrder.longitud
    }
    let position = null;
    let mensaje = "";
    switch (currentOrder.estado) {
        case "NoIniciado":
            mensaje = "La entrega del pedido no ha iniciado aún"
            break;
        case "ASIGNADO":
            mensaje = "El pedido ha sido asignado a un responsable para su entrega. Pronto iniciará la entrega"
            break;
        case "ENTREGADO":
            mensaje = "El pedido ya ha sido Entregado"
            break;
        case "EN_CAMINO":
            mensaje = "La entrega del Pedido se encuentra en camino"
            position = {
                lat : currentOrder.latitudActual,
                lng : currentOrder.longitudActual
            }
            break;
        case "EN_PUERTA":
            mensaje = "El responsable de la entrega de su pedido, se encuentra en su ubicación"
            position = {
                lat : currentOrder.latitudActual,
                lng : currentOrder.longitudActual
            }
            break;
        
        default:
            break;
    } 
        

    return (
        <>
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>
                            Estado de Entrega del Pedido { currentOrder.codigo }
                        </h1>
                    </div>

                    <div className="column">
                        <ul className="breadcrumbs">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>
                                <Link to={ `/entrega/${ ventaId }` }>Entrega de Pedido</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            

            <div className="container padding-bottom-3x">
                <div className="row">
              
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h3>
                                    { mensaje }
                                </h3>
                                {
                                    currentOrder.estado != "NoIniciado" &&
                                    <>
                                    <ResponsibleData nombreResponsable={ currentOrder.nombreResponsable }
                                        telefonoResponsable={ currentOrder.telefonoResponsable } />

                                    <Tracking destiny={ destiny }
                                        position={ position } />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Delivery;
