export const formatNumber = (num) => {
    return num.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const strTofriendlyUrl = (string) => {
    //source from: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
  
    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
}

//Selecciona los descuentos aplicables para un articulo en base a la lista de todos los descuentos
export const getDiscountsForArticle = (allDiscounts, articleId, familyId) => {
    
    const result = [];
    for(let i in allDiscounts){
        const o = allDiscounts[i];
        if(((o.objectType == "ARTICULO" && o.objectId == articleId) || 
        (o.objectType == "FAMILIA" && o.objectId == familyId))){
            result.push(o)
        }
    }
    return result;
}

//Obtiene el porcentaje de descuento de la lista de descuentos en base a la cantidad 
//seleccionada
export const chooseDiscount = (articleDiscounts, quantity, realPrice) => {
    let choosenDiscount = null;
    for(let i in articleDiscounts){
        const o = articleDiscounts[i];
        let max = o.cantidadMax;
        if (max < 0)
            max = Number.MAX_SAFE_INTEGER;
        if (quantity >= o.cantidadMin && quantity <= max){
            const discount = Math.min(o.descuentoXPorcentaje ? 
                o.porcentajeDescuento / 100.0 : 
                o.precioDescuento / realPrice , 1);
            if(!choosenDiscount || discount > choosenDiscount.discount)
                choosenDiscount = {
                    info : o,
                    discount : discount
                };
        }
        
    }
    return choosenDiscount;
}

export const emailIsValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const hasOnlyNumbers = (input) =>{
    const re = /^\d+$/;
    return re.test(input)
}

export const formatDate = (date) => {

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear();

    return `${ day < 10 ? "0": "" }${ day }/${ month < 10 ? "0": "" }${ month }/${ year }`
}